<div class="blank-container">
  <div class="blank-container-box">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="text-center">
            <img alt="homepage" src="assets/images/logo/logo.png" height="55"/>
            <h4 class="m-t-0">Recupero password</h4>
          </div>
          <div fxLayout="row wrap">
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field style="width: 100%">
                <input
                  matInput
                  placeholder="Email"
                  type="email"
                  [formControl]="form.get('email')"
                />
              </mat-form-field>
              <small
                *ngIf="
                  form.controls['email'].hasError('required') && form.controls['email'].touched
                "
                class="text-danger support-text"
              >
                * Campo obbligatorio
              </small>
              <small
                *ngIf="form.controls['email'].errors?.['email'] && form.controls['email'].touched"
                class="text-danger support-text"
              >
                * Campo non valido
              </small>
            </div>
            <button
              mat-raised-button
              color="primary"
              class="btn-block btn-lg m-t-20 m-b-20"
              type="submit"
              [disabled]="!form.valid"
            >
              Recupera password
            </button>

            <div class="text-center" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <span
              > &nbsp;
                <a [routerLink]="['/authentication/login']" class="link text-info"> Torna alla login </a>
              </span>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>

import {Component, Input} from "@angular/core";
import {CtBinaryOperator, CtModelConfiguration, CTModelDatatableFilter} from "@ctsolution/ct-framework";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../../_core/constants";
import {HandlingRouteData} from "../../../../_core/route-data/handling.route-data";

@Component({
  selector: "app-warehouse-handlings",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class WarehouseHandlingsComponent {

  @Input() referenceId: string | null = null;
  configuration: CtModelConfiguration<WarehouseHandlingsComponent> | null = null;

  ngAfterViewInit() {

    if (!this.referenceId) return;

    this.configuration = CtModelConfiguration
      .create<WarehouseHandlingsComponent>()
      .setRouteData(HandlingRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setFilterValues([
        CTModelDatatableFilter
          .create()
          .setField('LocationFrom.Oid')
          .setValue(this.referenceId)
          .setOperatorType(CtBinaryOperator.Equal),
        CTModelDatatableFilter
          .create()
          .setField('LocationTo.Oid')
          .setValue(this.referenceId)
          .setOperatorType(CtBinaryOperator.Equal),
      ])
      .setFieldsCustomTemplates([
        {prop: "LocationFrom", visible: false},
        {prop: "LocationTo", visible: false}
      ])

  }

}

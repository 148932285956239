import {Component, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {CtButtonConfiguration, MAT_RAISED_PRIMARY} from "@ctsolution/ct-framework";
import {ModuleCreateService} from "./module-create/module-create.service";
import {BeaverHeaderConfiguration} from "../../../layouts/beaver-header/beaver-header.configuration";
import {ItemHandling} from "../../../_core/classes/item-handling";
import {ModuleItemsComponent} from "./module-items/module-items.component";

@Component({
  selector: "app-catalog-detail",
  templateUrl: "./catalog-detail.component.html",
  styleUrls: ["./catalog-detail.component.scss"]
})
export class CatalogDetailComponent {

  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;
  @ViewChild(ModuleItemsComponent) moduleItemsComponent: ModuleItemsComponent | null = null;

  pId: string | null = null;
  pCode: string | null = null;
  headerConfiguration: BeaverHeaderConfiguration | null = null;
  createButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Genera moduli")
    .setIcon("auto_fix_normal")
    .setAction(() => this.createModule())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    private route: ActivatedRoute,
    private moduleCreateService: ModuleCreateService) {

    this.pId = this.route.snapshot.paramMap.get("id");

    this.route
      .queryParams
      .subscribe(qp => {

        this.headerConfiguration = BeaverHeaderConfiguration.create()
          .setTitle("Dettaglio modulo" + (qp["Name"] ? `: ${qp["Name"]}` : ""));

        if (qp["Code"]) this.pCode = qp["Code"];

      });

  }

  ngAfterViewInit() {

    this.headerConfiguration
      ?.setTemplateRef(this.headerActions);

  }

  createModule() {

    if (!this.pId) return;

    this.moduleCreateService
      .open(ItemHandling.create().setModuleOid(+this.pId))
      .beforeClosed()
      .subscribe(result => {

        if (result) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

      });
  }

}

<div class="handling-generic-info-cntr">

  <mat-accordion [multi]="true">
    <mat-expansion-panel [expanded]="true" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Dettaglio {{ handlingOriginSupplier ? 'ordine' : 'movimentazione'}}
        </mat-panel-title>
        <mat-panel-description *ngIf="form.value.Number">

          #{{ form.value.Number }}

        </mat-panel-description>

        <ng-container *ngIf="nextStep || handlingIsDeliveredStatus">

          <mat-divider vertical *ngIf="form.value.Number"></mat-divider>

          <button
            *ngIf="nextStep"
            mat-button
            color="primary"
            [disabled]="form.invalid"
            (click)="changeState($event)">Passa a "{{ nextStep.label }}"
          </button>

          <button
            *ngIf="handlingIsDeliveredStatus"
            mat-button
            color="primary"
            (click)="downloadPdf($event)"> Download PDF
            <mat-icon>download</mat-icon>
          </button>

        </ng-container>

      </mat-expansion-panel-header>

      <form
        class="handling-generic-form-info-cntr"
        [formGroup]="form">

        <div [fxLayout]="'row wrap'">

          <div
            class="form-control-cntr"
            [fxFlex]="50"
            [fxFlex.sm]="100"
            [fxFlex.xs]="100">

            <div
              [hidden]="!handlingOriginSupplier"
              class="supplier-control-cntr">

              <ct-control [configuration]="supplierFromControl"></ct-control>

            </div>

            <div
              [hidden]="handlingOriginSupplier"
              class="location-control-cntr">

              <ct-control [configuration]="locationFromControl"></ct-control>

            </div>

          </div>

          <div
            class="form-control-cntr"
            [fxFlex]="50"
            [fxFlex.sm]="100"
            [fxFlex.xs]="100">

            <ct-control [configuration]="locationToControl"></ct-control>

          </div>

        </div>

        <div [fxLayout]="'row wrap'">

          <div
            class="form-control-cntr"
            [fxFlex]="50"
            [fxFlex.sm]="100"
            [fxFlex.xs]="100">

            <ct-control [configuration]="internalNotesControl"></ct-control>

          </div>

          <div
            class="form-control-cntr"
            [fxFlex]="50"
            [fxFlex.sm]="100"
            [fxFlex.xs]="100">

            <ct-control [configuration]="documentNotesControl"></ct-control>

          </div>

        </div>

      </form>
    </mat-expansion-panel>
  </mat-accordion>

</div>

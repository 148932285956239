<ng-container *ngIf="pId; else errorTemplate">

  <beaver-header [configuration]="headerConfiguration"></beaver-header>

  <mat-tab-group>

    <mat-tab label="Articoli">

      <app-module-items [referenceId]="pId"></app-module-items>

    </mat-tab>

    <mat-tab label="Giacenze">

      <app-module-stocks [referenceId]="pId"></app-module-stocks>

    </mat-tab>

    <mat-tab
      *ngIf="pCode"
      label="Movimentazioni">

      <app-module-handlings [referenceCode]="pCode"></app-module-handlings>

    </mat-tab>

  </mat-tab-group>

</ng-container>
<ng-template #headerActions>

  <ct-button *ngIf="moduleItemsComponent?.moduleHasItems" [configuration]="createButton"></ct-button>

</ng-template>
<ng-template #errorTemplate>

  <p> An error occurred. If this issue persists please contact us through our help center at info@ctsolution.it</p>

</ng-template>

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ItemSelectionImportService} from "./item-selection-import.service";
import {ItemSelectionImportComponent} from "./item-selection-import.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {CtFileUploaderModule} from "@ctsolution/ct-framework";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {BeaverImportModule} from "../../../../../../layouts/beaver-import/beaver-import.module";


@NgModule({
  declarations: [
    ItemSelectionImportComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    CtFileUploaderModule,
    MatListModule,
    BeaverImportModule,
    MatDialogModule
  ],
  exports: [
    ItemSelectionImportComponent
  ],
  providers: [
    ItemSelectionImportService
  ]
})
export class ItemSelectionImportModule {
}

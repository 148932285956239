import {Component, Input} from "@angular/core";
import {
  CtBinaryOperator,
  CtModelConfiguration, CTModelDatatableFilter
} from "@ctsolution/ct-framework";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../../_core/constants";
import {LocationStockRouteData} from "../../../../_core/route-data/location-stock.route-data";

@Component({
  selector: "app-warehouse-stocks",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class WarehouseStocksComponent {

  @Input() referenceId: string | null = null;
  configuration: CtModelConfiguration<WarehouseStocksComponent> | null = null;

  ngAfterViewInit() {

    if (!this.referenceId) return;

    this.configuration = CtModelConfiguration
      .create<WarehouseStocksComponent>()
      .setRouteData(LocationStockRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setFilterValues([
        CTModelDatatableFilter
          .create()
          .setField('Location.Oid')
          .setValue(this.referenceId)
          .setOperatorType(CtBinaryOperator.Equal)
      ])
      .setFieldsCustomTemplates([{prop: "Location", visible: false}]);

  }

}

import {CTBase} from "@ctsolution/ct-framework";
import {BeaverItem} from "./beaver-item";
import {Handling} from "./handling";
import {BeaverUM} from "./beaver-um";

export class ItemHandling extends CTBase<ItemHandling> {

  Oid: number | null = null;
  StartQty: number | null = null;
  Qty: number | null = null;
  Item: BeaverItem | null = null;
  Module: BeaverItem | null = null;
  Handling: Handling | null = null;
  UM: BeaverUM | null = null;
  ItemCategoryTrackEnable: boolean | null = null;

  constructor();
  constructor(model?: ItemHandling)
  constructor(model?: ItemHandling) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: ItemHandling): ItemHandling => new ItemHandling(model);

  setStartQty(value: number | null): ItemHandling {

    this.StartQty = value;
    return this;

  }

  setQty(value: number | null): ItemHandling {

    this.Qty = value;
    return this;

  }

  setItemOid(Oid: number | null): ItemHandling {

    this.Item = {Oid} as BeaverItem;
    return this;

  }

  setModuleOid(Oid: number | null): ItemHandling {

    this.Module = {Oid} as BeaverItem;
    return this;

  }

  setUMOid(Oid: number | null): ItemHandling {

    this.UM = {Oid} as BeaverUM;
    return this;

  }

}

import {Component} from "@angular/core";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent {

  constructor(
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private http: HttpClient) {

    translate.setDefaultLang("it");

  }

  ngOnInit() {

    if (!environment.production) {

      this.snackbar.open("Modalità di Sviluppo Attiva", "X", {duration: 3000});

    }

    if ((<any>environment).customTranslationsPath) {

      this.loadCustomTranslations((<any>environment).customTranslationsPath)
        .then((customTranslations) => {

          // Sovrascrivi le traduzioni combinate con le traduzioni personalizzate
          this.translate.setTranslation('it', customTranslations, true);
          this.translate.use('it');

        });

    }

  }

  loadCustomTranslations(path: string): Promise<any> {

    // Carica il file custom-translations.json
    return this.http
      .get<any>(path)
      .toPromise()
      .catch(() => ({})); // Se il file non esiste o ci sono errori nel caricamento, restituisci un oggetto vuoto

  }

}

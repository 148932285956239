import {Component, OnInit} from '@angular/core';
import {
  CtControlConfiguration, CTDatatablePaginationParameter,
  CTFieldInfos,
  CtFormContainerHelper, CtModelDatatableOperators, CtSelectControlOptions,
  CtSortOrderDescriptorParameter,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {environment} from "../../../../../../environments/environment";

const UserWorkingHoursWorkOrderControl: CTFieldInfos = CTFieldInfos.create({
  IsDTO: true,
  Field: "WorkOrder",
  Type: "WorkOrderDTO",
  IsNullable: false,
  ControlType: "Lookup",
  LookupInfos: {
    RemoteEndpoint: `${environment.dev.REST_API_SERVER}/WorkOrder/Lookup`,
    CodeField: "Code",
    DescriptionField: "Description",
    IncrementalSearch: false
  },
} as CTFieldInfos);

@Component({
  selector: 'app-user-working-hours-work-order-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursWorkOrderControlComponent implements OnInit {

  control: CtControlConfiguration | null = null;

  ngOnInit() {

    this.setup();

  }

  setup() {

    const configuration: CtControlConfiguration = CtFormContainerHelper
      .create()
      .getCTFormControlByCTFieldInfo(UserWorkingHoursWorkOrderControl);

    this.control = CtControlConfiguration
      .create(configuration)
      .setTheme(CtThemeTypes.MATERIAL)

    const options: CtSelectControlOptions | null = this.control?.options as CtSelectControlOptions;

    if (!options) this.control?.setOptions(CtSelectControlOptions.create());

    options
      .setLookupFilter(() => {

        const filter = CtModelDatatableOperators
          .create()
          .setPagination(new CTDatatablePaginationParameter())

        if (filter.Pagination) filter.setPagination(new CTDatatablePaginationParameter())

        filter
          .Pagination
          ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(new CtSortOrderDescriptorParameter().setField('Name')))

        return filter;

      });

    options
      .setNullValueEnabled(true);

    options
      .notifyValuesUpdated()

  }

}

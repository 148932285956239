import {Component} from '@angular/core';
import {
  CtControlConfiguration, CtControlValidator, CTFieldInfos,
  CtFormContainerHelper,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {environment} from "../../../../../../environments/environment";

const UserWorkingHoursActivityTypeControl: CTFieldInfos = CTFieldInfos.create({
  IsDTO: true,
  Field: "ActivityType",
  Type: "ActivityTypeDTO",
  IsNullable: false,
  ControlType: "Lookup",
  LookupInfos: {
    RemoteEndpoint: `${environment.dev.REST_API_SERVER}/ActivityType/Lookup`,
    CodeField: "Code",
    DescriptionField: "Description",
    IncrementalSearch: false,
  },
} as CTFieldInfos);

@Component({
  selector: 'app-user-working-hours-activity-type-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursActivityTypeControlComponent {

  control: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHoursActivityTypeControl))
    .setTheme(CtThemeTypes.MATERIAL)
    .setValidators([
      CtControlValidator.create({name: 'required'} as CtControlValidator)
    ])

}

import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ModuleCreateComponent} from "./module-create.component";
import {ItemHandling} from "../../../../_core/classes/item-handling";

@Injectable()
export class ModuleCreateService {

  constructor(private dialog: MatDialog) {
  }

  open = (data: ItemHandling) => this.dialog
    .open(ModuleCreateComponent, {
      data,
      maxWidth: 500,
      panelClass: "module-create-container"
    });

}

import {TableColumn} from "@swimlane/ngx-datatable";

export const ITEM_STOCK_SELECTION_COLUMNS: TableColumn[] = [
  {
    prop: "ItemCode",
    name: "Codice"
  },
  {
    prop: "ItemName",
    name: "Nome"
  },
  {
    prop: "ItemDescription",
    name: "Descrizione",
    width: 300
  },
  {
    prop: "Stock",
    name: "Quantità"
  },
  {
    prop: "UMSymbol",
    name: "UM"
  }
];

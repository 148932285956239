import {Injectable} from "@angular/core";
import {DataRequest} from "@ctsolution/ct-webapi";
import {ControllerHelper, DataSourceListElement} from "./controller.helper";
import {CtModelConfiguration} from "@ctsolution/ct-framework";

const CONTROLLER: string[] = ["supplier"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class SupplierController {

  constructor(private _helper: ControllerHelper) {
  }

  get = (): Promise<CtModelConfiguration<Array<DataSourceListElement>>> => this._helper.list(CONTROLLER_REQUEST());

}

import {Component} from '@angular/core';
import {
  CtControlConfiguration,
  CtControlTypes, CtControlValidator,
  CTFieldInfos,
  CtFormContainerHelper,
  CtThemeTypes
} from "@ctsolution/ct-framework";

const UserWorkingHourNotesControl: CTFieldInfos = CTFieldInfos
  .create({
    Field: "Note",
    Type: "InputText",
    ControlType: "InputText",
    IsNullable: true
  } as CTFieldInfos);

@Component({
  selector: 'app-user-working-hours-notes-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursNotesControlComponent {

  control: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHourNotesControl))
    .setType(CtControlTypes.TEXTAREA)
    .setTheme(CtThemeTypes.MATERIAL)
    .setValidators([
      CtControlValidator.create({name: 'required'} as CtControlValidator),
    ])

}

import {Component, OnInit} from "@angular/core";
import {
  CtDatatableActionConfiguration,
  CtDatatableConfiguration,
  CtModelConfiguration
} from "@ctsolution/ct-framework";
import {Router} from "@angular/router";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {GeneralService} from "../../../_core/lib/general.service";
import {WorkOrderRouteData} from "../../../_core/route-data/work-order.route-data";
import {UserCompanySettings} from "../../../_core/classes/user-company-settings";
import {JwtService} from "../../../_core/lib/jwt.service";

@Component({
  selector: "app-work-order-list",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class WorkOrderListComponent implements OnInit {

  depositRedirectCTA: CtDatatableActionConfiguration = new CtDatatableActionConfiguration()
    .setColor("primary")
    .setIcon("warehouse");

  datatableConfiguration: CtDatatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION();

  configuration: CtModelConfiguration<WorkOrderListComponent> = CtModelConfiguration
    .create<WorkOrderListComponent>()
    .setRouteData(WorkOrderRouteData())
    .setCTDatatableConfiguration(this.datatableConfiguration)
    .setOnCreate(() => this.general.navigateOnCTModelRouter(["work-order"], "create"))
    .setOnEdit(event => this.general.navigateOnCTModelRouter(["work-order"], "edit", {
      value: event,
      configuration: this.configuration
    }));

  constructor(
    private general: GeneralService,
    private router: Router,
    private jwt: JwtService
  ) {
  }

  ngOnInit() {

    this.setup();

  }

  async setup() {

    const configuration: UserCompanySettings | null = await this.jwt.getUserCompanySettings();

    if (configuration?.UserHasWarehouseManagementEnabled) {

      this.datatableConfiguration
        .setCustomDatatableAction(
          this.depositRedirectCTA,
          (event => {
            this.router.navigate(["location", "stores"], {
              queryParams: {
                WorkOrder: event.Oid,
                WorkOrderName: event.Name
              }
            })
          }));

    }

  }

}

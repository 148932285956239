import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {WorkOrderEditComponent} from "./work-order-edit/work-order-edit.component";
import {WorkOrderListComponent} from "./work-order-list/work-order-list.component";
import {WorkOrderEditModule} from "./work-order-edit/work-order-edit.module";
import {WorkOrderListModule} from "./work-order-list/work-order-list.module";

@NgModule({
  imports: [
    CommonModule,
    WorkOrderEditModule,
    WorkOrderListModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "create",
            component: WorkOrderEditComponent
          },
          {
            path: "edit/:id",
            component: WorkOrderEditComponent
          },
          {
            path: "list",
            component: WorkOrderListComponent
          }
        ]
      }
    ])
  ]
})
export class WorkOrderModule {
}

<ng-container *ngIf="(model?.Handlings ?? []).length; else errorTemplate">

  <div class="module-create-handlings-cntr">

    <ng-container *ngIf="handlings.length">

      <p> Verranno generate le seguenti movimentazioni: </p>

      <app-module-item-order [handlings]="handlings"></app-module-item-order>

    </ng-container>

    <ng-container *ngIf="orders.length">

      <p> Verranno generati i seguenti ordini: </p>

      <app-module-item-order [handlings]="orders"></app-module-item-order>

      <mat-divider></mat-divider>

      <small> Nota Bene: Dopo la conclusione degli ordini, sarà necessario ripetere la
        procedura di generazione del modulo.</small>

    </ng-container>

  </div>

  <p> Confermi di voler proseguire? </p>

</ng-container>

<ng-template #errorTemplate>

  <p> Si è verificato un'errore, riprovare.</p>

</ng-template>

import {Component} from "@angular/core";
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelOperator
} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {CatalogService} from "../catalog.service";
import {GeneralService} from "../../../_core/lib/general.service";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {ItemType} from "../../../_core/enum/item-type";
import {ItemRouteData} from "../../../_core/route-data/item.route-data";

@Component({
  selector: "app-catalog-list",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class CatalogListComponent {

  configuration: CtModelConfiguration<CatalogListComponent> | null = null;

  constructor(
    private general: GeneralService,
    private route: ActivatedRoute,
    private catalogService: CatalogService) {

    const configuration = CtModelConfiguration
      .create<CatalogListComponent>()
      .setRouteData(ItemRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setFieldsCustomTemplates([{prop: "Type", visible: false}]);

    const typePathValue: string | null = this.route.snapshot.paramMap.get("type");

    if (!typePathValue) return;

    const constantType: ItemType | null = this.catalogService.getCatalogTypeByRouteValue(typePathValue);

    if (constantType) {

      configuration
        .setFilterValues([
          CTModelDatatableFilter
            .create()
            .setField('Type')
            .setValue(constantType)
            .setOperatorType(CtBinaryOperator.Equal)
        ]);

      if (constantType === ItemType.Module) {

        configuration
          .setOperations([CtModelOperator.Detail])
          .setOnDetail(event => this.general.navigateOnCTModelRouter(["catalog", typePathValue], "detail", {
            value: event,
            configuration: this.configuration!,
            qpKeys: ["Name", "Code"]
          }));

        this
          .route
          .data
          .subscribe(data => {
            data["title"] = "Moduli";
          });

      }

    }

    configuration
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["catalog", typePathValue], "create"))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["catalog", typePathValue], "edit", {
        value: event,
        configuration: this.configuration!
      }));

    this.configuration = configuration;

  }

}

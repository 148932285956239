import {
  CtControlConfiguration,
  CtControlTypes
} from "@ctsolution/ct-framework";

export const LocationFromControl: CtControlConfiguration = <CtControlConfiguration>{
  name: "LocationFromOid",
  label: "Deposito Partenza",
  placeholder: "Deposito Partenza",
  type: CtControlTypes.ENUMERABLE
};

export const SupplierFromControl: CtControlConfiguration = <CtControlConfiguration>{
  name: "SupplierFromSupplierOid",
  label: "Fornitore Partenza",
  placeholder: "Fornitore",
  type: CtControlTypes.ENUMERABLE
};


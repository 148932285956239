import {Injectable} from '@angular/core';
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {UserCompanySettings} from "../classes/user-company-settings";
import {CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor(private CTAuthenticationService: CtAuthenticationService, private _webapi: CtWebapiService) {
  }

  async getUserEmail(): Promise<string | null> {

    await this.CTAuthenticationService.claimsUploaded();
    return this.CTAuthenticationService.getJwtValueByContainedKey('email', false);

  }

  async getUserInfoOid(): Promise<string | null> {

    await this.CTAuthenticationService.claimsUploaded();
    return this.CTAuthenticationService.getJwtValueByContainedKey('Oid');

  }

  async getDailyWorkingHours(): Promise<number | null> {

    await this.CTAuthenticationService.claimsUploaded();

    const value: string | null = this.CTAuthenticationService.getJwtValueByContainedKey('DailyWorkingHours');

    if (value && !isNaN(+value)) return +value;

    return null;

  }

  async getUserCompanySettings(): Promise<UserCompanySettings | null> { // in realtà questa configurazione me lo passerà l'API

    try {
      const request: DataRequest = new DataRequest(`${window.location.origin}/assets/temp-company-settings.json?hideError=true`);

      const configuration: UserCompanySettings = await this._webapi
        .get(request)
        .toPromise();

      if (!configuration) return null;

      return UserCompanySettings.create(configuration);
    } catch (error) {
      // Gestisci l'errore qui
      console.error("Si è verificato un errore durante la richiesta delle impostazioni dell'azienda:", error);
      return null;
    }
  }

}

import {Component} from '@angular/core';
import {CtModelConfiguration, CtModelRouteData, CtModelType} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {UserRouteData} from "../../../_core/route-data/user.route-data";
import {CtFieldCustomColumn} from "@ctsolution/ct-framework/lib/ct-datatable/_core/ct-field-custom.column";
import {UserCompanySettings} from "../../../_core/classes/user-company-settings";
import {JwtService} from "../../../_core/lib/jwt.service";

@Component({
  selector: 'app-user-edit',
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class UserEditComponent {

  configuration: CtModelConfiguration<UserEditComponent> | null = null;

  constructor(private route: ActivatedRoute, private jwt: JwtService) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    const routeData: CtModelRouteData = UserRouteData()
      .setModelType(CtModelType.FORM);

    const pId: string | null = this.route.snapshot.paramMap.get("id");

    if (pId) routeData.setId(+pId);

    const configuration: UserCompanySettings | null = await this.jwt.getUserCompanySettings();

    const fieldsCustomTemplates: CtFieldCustomColumn[] = [
      {prop: "DailyWorkingHours", visible: configuration?.UserHasMonitoringManagementEnabled ?? false},
      {prop: "WeeklyWorkingHours", visible: configuration?.UserHasMonitoringManagementEnabled ?? false},
      {prop: "WorkJob", visible: configuration?.UserHasMonitoringManagementEnabled ?? false},
    ];

    this.configuration = CtModelConfiguration
      .create<UserEditComponent>()
      .setRouteData(routeData)
      .setFieldsCustomTemplates(fieldsCustomTemplates);

  }

}

<h1 mat-dialog-title>Selezione indirizzo</h1>
<div mat-dialog-content>

  <mat-selection-list [multiple]="false" #address>
    <mat-list-option *ngFor="let item of data" [value]="item.Address">
      {{item.Address}}
    </mat-list-option>
  </mat-selection-list>

</div>
<div
  mat-dialog-actions
  fxLayout="row wrap"
  fxLayoutAlign="space-between center">
  <button mat-button color="warn" (click)="close()">Annulla</button>
  <button mat-button color="primary" (click)="save()" cdkFocusInitial>Salva</button>
</div>

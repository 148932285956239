import {
  CtButtonConfiguration,
  CtCardConfiguration,
  CtCardIconConfiguration,
  CtCardIconPosition, CtCardTemplate
} from "@ctsolution/ct-framework";

//# region BIG CARDS
export const NEW_REGISTER_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("Nuovo registro ore")
  .setDescription("Inserisci ore per tenere traccia delle attività")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setIcon("alarm_on")
      .setClass("bg-beaver-alt text-white")
      .setPosition(CtCardIconPosition.SUFFIX))
  .setClass("bg-beaver-alt text-white")
  .setButton(CtButtonConfiguration.create().setIcon("arrow_forward"));
//# endregion BIG CARDS

//# region CARDS
export const REGISTER_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("Visualizza il registro delle ore")
  .setDescription("Consulta il registro completo delle ore")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-info text-white")
      .setIcon("build"));
export const WORK_ORDER_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("Esplora tutti i cantieri")
  .setDescription("Visualizza l'elenco completo dei cantieri")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-danger text-white")
      .setIcon("exposure_plus_1"));
//# endregion CARDS

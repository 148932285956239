import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CatalogEditComponent} from "./catalog-edit.component";
import {CtModelModule} from "@ctsolution/ct-framework";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";

@NgModule({
  declarations: [
    CatalogEditComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    MatListModule
  ]
})
export class CatalogEditModule {
}

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DashboardComponent} from "./dashboard.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {CtCardModule} from "@ctsolution/ct-framework";
import {FlexLayoutModule} from "@angular/flex-layout";
import {
  UserWorkingHoursWizardModule
} from "../../components/user-working-hours/user-working-hours-wizard/user-working-hours-wizard.module";

@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    CtCardModule,
    FlexLayoutModule,
    UserWorkingHoursWizardModule
  ]
})
export class DashboardModule {
}

import {Component, Input} from "@angular/core";
import {CtBinaryOperator, CtModelConfiguration, CTModelDatatableFilter} from "@ctsolution/ct-framework";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../../_core/constants";
import {HandlingRouteData} from "../../../../_core/route-data/handling.route-data";

@Component({
  selector: "app-module-handlings",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class ModuleHandlingsComponent {
  @Input() referenceCode: string | null = null;
  configuration: CtModelConfiguration<ModuleHandlingsComponent> | null = null;

  ngAfterViewInit() {

    if (!this.referenceCode) return;

    this.configuration = CtModelConfiguration
      .create<ModuleHandlingsComponent>()
      .setRouteData(HandlingRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setFilterValues([
        CTModelDatatableFilter
          .create()
          .setField("GroupCode")
          .setValue(this.referenceCode)
          .setOperatorType(CtBinaryOperator.Contains)
      ])
      .setFieldsCustomTemplates([{prop: "GroupCode", visible: false}])

  }

}

export const environment = {
  production: false,
  projectName: "Repetto Manager", // RepettoDemo
  dev: {
    REST_API_SERVER: "https://repettomanager.demo.api.testctsolution.it/api"
  },
  variables: {
    defaults: {
      inventoryOid: 1
    }
  },
  customTranslationsPath: '/assets/custom-translations.json'
};

import {Component, OnInit} from '@angular/core';
import {CtModelConfiguration} from "@ctsolution/ct-framework";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {GeneralService} from "../../../_core/lib/general.service";
import {RoleService} from "../../../_core/lib/role.service";
import {UserWorkingHoursWizardService} from "../user-working-hours-wizard/user-working-hours-wizard.service";
import {UserWorkingHoursRouteData} from "../../../_core/route-data/user-working-hours.route-data";

@Component({
  selector: 'app-user-working-hours-list',
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class UserWorkingHoursListComponent implements OnInit {

  configuration: CtModelConfiguration<UserWorkingHoursListComponent> | null = null;

  constructor(
    private general: GeneralService,
    private role: RoleService,
    private userworkinghoursWizardService: UserWorkingHoursWizardService
  ) {
  }

  ngOnInit() {

    this.setup();

  }

  private reload() {

    this.configuration = null;
    setTimeout(() => this.setup());

  }

  async setup() {

    const isAdministrator = await this.role.is.administrator();

    this.configuration = CtModelConfiguration
      .create<UserWorkingHoursListComponent>()
      .setRouteData(UserWorkingHoursRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setOnCreate(() => {

        this.userworkinghoursWizardService
          .open()
          .beforeClosed()
          .subscribe(() => this.reload())
      })
      .setOnEdit(event => {

        this.userworkinghoursWizardService
          .open(event)
          .beforeClosed()
          .subscribe(() => this.reload())

      })
      .setFieldsCustomTemplates([
        {
          prop: 'UserNameInfo',
          visible: isAdministrator
        }
      ])

  }

}

import {Injectable} from "@angular/core";
import {CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {CtThemeMenuConfiguration} from "./_classes/ct-theme-menu.configuration";
import {ChildrenItems, Menu} from "./_classes/menu-item.interface";
import {UserCompanySettings} from "../../../_core/classes/user-company-settings";
import {JwtService} from "../../../_core/lib/jwt.service";
import {RoleService} from "../../../_core/lib/role.service";

const configurationMenuItemsContainer: Menu = {
  state: "ct-model",
  name: "configurations",
  type: "sub",
  icon: "admin_panel_settings",
  children: []
}

@Injectable()
export class MenuItems {

  constructor(private _webapi: CtWebapiService, private jwt: JwtService, private roleService: RoleService) {
  }

  async getMenuItems(): Promise<Menu[]> {

    let items: Menu[] = await this.getUserStandardMenu() as Menu[];

    configurationMenuItemsContainer.children = new Array<ChildrenItems>();

    const controlPanelEnabled: boolean = await this.roleService.is.administrator();

    const configuration: UserCompanySettings | null = await this.jwt.getUserCompanySettings();

    /**
     * WAREHOUSE MANAGER ITEMS
     * */

    const warehouseManagementEnabled: boolean = configuration?.UserHasWarehouseManagementEnabled ?? false;

    if (warehouseManagementEnabled) {

      const warehouseManagerMenuItems: Menu[] = await this.getWarehouseManagerMenu() as Menu[];
      items = items.concat(warehouseManagerMenuItems);

      if (controlPanelEnabled) {

        const warehouseManagerConfigurationsMenuItems: ChildrenItems[] = await this.getWarehouseManagerConfigurationsMenu() as ChildrenItems[];
        configurationMenuItemsContainer.children = configurationMenuItemsContainer.children?.concat(warehouseManagerConfigurationsMenuItems);

      }

    }

    /**
     * MONITORING MANAGER ITEMS
     * */

    const monitoringManagementEnabled: boolean = configuration?.UserHasMonitoringManagementEnabled ?? false;

    if (monitoringManagementEnabled) {

      const monitoringMenuItems: Menu[] = await this.getMonitoringMenu() as Menu[];
      items = items.concat(monitoringMenuItems);

      if (controlPanelEnabled) {

        const monitoringConfigurationsMenuItems: ChildrenItems[] = await this.getMonitoringConfigurationsMenu() as ChildrenItems[];
        configurationMenuItemsContainer.children = configurationMenuItemsContainer.children?.concat(monitoringConfigurationsMenuItems);

      }

    }

    /**
     * CONTROL PANEL ITEMS
     * */

    if (controlPanelEnabled) {

      const controlPanelMenuItems: Menu[] = await this.getControlPanelMenu() as Menu[];
      items = items.concat(controlPanelMenuItems);

      if (configurationMenuItemsContainer.children?.length) {

        items.push(configurationMenuItemsContainer)

      }

    }

    return items; // ritorno il menu valorizzato con il pannello di controllo messo in fondo

  }

  getUserStandardMenu = () => this.getMenu('user');

  getWarehouseManagerMenu = () => this.getMenu('warehouse-manager');

  getWarehouseManagerConfigurationsMenu = () => this.getMenu('warehouse-manager-configurations');

  getControlPanelMenu = () => this.getMenu('control-panel');

  getMonitoringMenu = () => this.getMenu('monitoring');

  getMonitoringConfigurationsMenu = () => this.getMenu('monitoring-configurations');

  private async getMenu(pathName: string): Promise<Menu[] | ChildrenItems[]> {

    const request: DataRequest = new DataRequest(`${window.location.origin}/assets/json/${pathName}.menu.json`);

    const configuration: CtThemeMenuConfiguration = await this._webapi
      .get(request)
      .toPromise();

    return configuration.items;

  }

}

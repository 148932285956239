<div
  class="beaver-action-title-cntr"
  fxLayout="row wrap"
  fxLayoutAlign="space-between center">

  <h3> <!-- {{ configuration?.title }} --></h3>

  <div class="header-actions">

    <ng-container *ngTemplateOutlet="configuration?.actionsTemplate"></ng-container>

  </div>

</div>

<ng-container *ngIf="pId; else errorTemplate">

  <beaver-header [configuration]="headerConfiguration"></beaver-header>

  <mat-tab-group>

    <mat-tab label="Giacenze">

      <app-warehouse-stocks [referenceId]="pId"></app-warehouse-stocks>

    </mat-tab>

    <mat-tab label="Movimentazioni">

      <app-warehouse-handlings [referenceId]="pId"></app-warehouse-handlings>

    </mat-tab>

  </mat-tab-group>

</ng-container>

<ng-template #headerActions>

  <ct-button [configuration]="submitButton"></ct-button>

  <button
    (click)="reportQR()"
    mat-mini-fab
    color="primary">
    <mat-icon>qr_code_2</mat-icon>
  </button>

  <button
    (click)="reportPDF()"
    mat-mini-fab
    color="accent">
    <mat-icon>picture_as_pdf</mat-icon>
  </button>


</ng-template>
<ng-template #errorTemplate>

  <p> An error occurred. If this issue persists please contact us through our help center at info@ctsolution.it</p>

</ng-template>

import { BeaverLocation } from "./beaver-location";
import { CTBase } from "@ctsolution/ct-framework";
import { BeaverItem } from "./beaver-item";

export class BeaverItemStock extends CTBase<BeaverItemStock> {
  Code: string | null = null;
  Location: BeaverLocation | null = null;
  ItemOid: number | null = null;
  ItemCode: string | null = null;
  ItemName: string | null = null;
  ItemDescription: string | null = null;
  Stock: number | null = null;
  UMOid: number | null = null;
  UMSymbol: string | null = null;

  constructor();
  constructor(model?: BeaverItemStock)
  constructor(model?: BeaverItemStock) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: BeaverItemStock): BeaverItemStock => new BeaverItemStock(model);

  public static createByBeaverItem(value: BeaverItem | null): BeaverItemStock {

    if (!value) return this.create();

    const newItemStock = this.create({

      ItemCode: value.Code,
      ItemName: value.Name,
      ItemOid: value.Oid

    } as BeaverItemStock);

    if (value.DefaultUnitOfMeasure) {

      newItemStock.UMOid = value.DefaultUnitOfMeasure.Oid;
      newItemStock.UMSymbol = value.DefaultUnitOfMeasure.Symbol;

    }

    return newItemStock;

  }

}

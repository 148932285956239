import {Component} from '@angular/core';
import {CtModelConfiguration} from "@ctsolution/ct-framework";
import {CUSTOMER_ROUTEDATA} from "../customer.module";
import {GeneralService} from "../../../_core/lib/general.service";

@Component({
  selector: 'app-customer-list',
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class CustomerListComponent {

  configuration: CtModelConfiguration<CustomerListComponent> = CtModelConfiguration
    .create<CustomerListComponent>()
    .setRouteData(CUSTOMER_ROUTEDATA())
    .setOnCreate(() => this.general.navigateOnCTModelRouter(["customer"], "create"))
    .setOnEdit(event => this.general.navigateOnCTModelRouter(["customer"], "edit", {
      value: event,
      configuration: this.configuration
    }));

  constructor(private general: GeneralService) {
  }


}

<div class="dashboard-container">

  <div fxLayout="row wrap">

    <div
      *ngIf="enableUserWorkingHoursWizard"
      fxFlex="100"
      [fxFlex.gt-md]="enableUserWorkingHoursWizard ? 35 : 100"
      [fxFlex.gt-lg]="enableUserWorkingHoursWizard ? 25 : 100">

      <app-user-working-hours-wizard></app-user-working-hours-wizard>

    </div>

    <div
      fxFlex="100"
      [fxFlex.gt-md]="enableUserWorkingHoursWizard ? 65 : 100"
      [fxFlex.gt-lg]="enableUserWorkingHoursWizard ? 75 : 100">

      <div fxLayout="row wrap">

        <div *ngFor="let counter of counterList"
             fxFlex="100"
             [fxFlex.gt-md]="enableUserWorkingHoursWizard ? 50 : 20"
             [fxFlex.gt-lg]="16.6">
          <ct-card [configuration]="counter"></ct-card>
        </div>

        <div *ngFor="let card of masterCardList"
             fxFlex="100"
             [fxFlex.gt-md]="enableUserWorkingHoursWizard ? 50 : 30"
             [fxFlex.gt-lg]="33.33">
          <ct-card [configuration]="card"></ct-card>
        </div>

        <div *ngFor="let card of cardList"
             fxFlex="100"
             [fxFlex.gt-md]="33.33">
          <ct-card [configuration]="card"></ct-card>
        </div>

      </div>

      <div fxLayout="row wrap">


      </div>

      <div fxLayout="row wrap">

        <div *ngFor="let card of masterCardList2" [fxFlex.gt-sm]="100/masterCardList2.length" fxFlex.gt-xs="100"
             fxFlex="100">
          <ct-card [configuration]="card"></ct-card>
        </div>

      </div>

    </div>

  </div>

</div>

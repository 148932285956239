<form
  class="selected-item-row-cntr"
  [fxLayout]="'row wrap'"
  [formGroup]="form">

  <div
    class="p-10"
    [fxFlex]="10"
    [fxFlex.sm]="15"
    [fxFlex.xs]="20"
    *ngIf="handlingIsDraftStatus"
  >

    <button
      (click)="delete()"
      mat-mini-fab
      color="warn">
      <mat-icon>delete</mat-icon>
    </button>

  </div>

  <div
    class="item-description-cntr p-10"
    [fxFlex]="handlingIsDraftStatus ? 40 : 50"
    [fxFlex.sm]="85"
    [fxFlex.xs]="80">

    <div><h3><strong>{{ configuration?.BeaverItemStock.ItemCode }}</strong>
      - {{ configuration?.BeaverItemStock.ItemName }} </h3></div>

    <div><p>  {{ configuration?.BeaverItemStock.ItemDescription }} </p></div>

  </div>

  <div
    class="p-10"
    [fxFlex]="25"
    [fxFlex.sm]="50"
    [fxFlex.xs]="100"
    align="end"
    [hidden]="!enableStartQtyControl">

    <ct-control [configuration]="configuration?.startQtyControl"></ct-control>

  </div>

  <div
    class="p-10"
    [fxFlex]="25"
    [fxFlex.sm]="50"
    [fxFlex.xs]="100"
    [class.text-center]="(configuration?.barcodes ?? []).length"
    [align]="(configuration?.barcodes ?? []).length ? null : 'end'">

    <div *ngIf="(configuration?.barcodes ?? []).length; else qtyInsert">

      <button mat-raised-button (click)="onTracking.emit()" class="m-t-15">
        Tracciati {{ configuration.barcodes.length }} barcode
      </button>

    </div>

    <ng-template #qtyInsert>

      <ct-control [configuration]="configuration?.qtyControl"></ct-control>

    </ng-template>

  </div>

  <div
    class="p-10"
    [fxFlex]="25"
    [fxFlex.sm]="50"
    [fxFlex.xs]="100"
    align="end"
    [hidden]="!handlingIsDraftStatus">

    <ct-control *ngIf="configuration?.UMControl" [configuration]="configuration?.UMControl"></ct-control>

  </div>

</form>

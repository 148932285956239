<mat-card class="selected-items-cntr">

  <mat-card-content>

    <mat-card-title-group>

      <mat-card-title>Articoli selezionati</mat-card-title>

    </mat-card-title-group>

    <ng-container *ngIf="!configuration?.selected.length; else listTemplate">

      <p> Nessun articolo selezionato</p>

    </ng-container>
    <ng-template #listTemplate>

      <ng-container *ngIf="handlingItemActionsEnabled; else recapViewList">

        <ng-container
          *ngFor="let row of configuration.selected ?? []">

          <app-selected-item-row
            [configuration]="row"
            (onTracking)="openBarcodeTracking(row)"
            (onDelete)="delete(row)">
          </app-selected-item-row>

          <mat-divider></mat-divider>

        </ng-container>

      </ng-container>

      <ng-template #recapViewList>

        <mat-list>
          <mat-list-item *ngFor="let selectedItem of (configuration?.selected ?? [])">
            <mat-icon matListItemIcon>build</mat-icon>
            <div mat-line>
              <strong> {{ selectedItem.BeaverItemStock.ItemCode }}</strong> {{ selectedItem.BeaverItemStock.ItemName }}
            </div>
            <div mat-line>{{selectedItem.BeaverItemStock.ItemDescription}}</div>
            <div mat-line>
              Quantità: {{ selectedItem.qtyControl.control.value }} {{ selectedItem.BeaverItemStock.UMSymbol  }}</div>
          </mat-list-item>
        </mat-list>

      </ng-template>

    </ng-template>

    <mat-card-actions>

      <div>

        <ct-button [configuration]="cancelButton"></ct-button>

      </div>

      <div>

        <ct-button class="m-r-5" *ngIf="barcodeTrackingCheckInEnabled"
                   [configuration]="barcodeTrackingButton"></ct-button>

        <ct-button *ngIf="!handlingDelivered" [configuration]="submitButton"></ct-button>

      </div>

    </mat-card-actions>

  </mat-card-content>

</mat-card>

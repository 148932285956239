import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserEditComponent} from "./user-edit.component";
import {CtModelModule} from "@ctsolution/ct-framework";


@NgModule({
  declarations: [UserEditComponent],
  imports: [
    CommonModule,
    CtModelModule
  ],
  exports: [UserEditComponent]
})
export class UserEditModule {
}

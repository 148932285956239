import {Component} from '@angular/core';

@Component({
  selector: 'app-module-create-loading-panel',
  template: `
    <div class="loading">

      <p>Stiamo verificando le giacenze a deposito per mostrarti i moduli che puoi generare.</p>
      <p>Attendere, per favore ...</p>

      <mat-progress-bar mode="indeterminate"></mat-progress-bar>

    </div>
  `
})
export class ModuleCreateLoadingPanelComponent {
}

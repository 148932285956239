import {Injectable} from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {ControllerHelper} from "./controller.helper";
import {CtModelConfiguration, CtModelDatatableOperators} from "@ctsolution/ct-framework";
import {BeaverItemStock} from "../classes/beaver-item-stock";
import {ItemHandling} from "../classes/item-handling";
import {Observable} from "rxjs";
import {Handling} from "../classes/handling";

const CONTROLLER: string[] = ["item"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class ItemController {

  constructor(private _helper: ControllerHelper, private _webapi: CtWebapiService) {
  }

  get = (operators: CtModelDatatableOperators): Promise<CtModelConfiguration<Array<BeaverItemStock>>> => this._helper.list(CONTROLLER_REQUEST()
    .setBody(operators));

  moduleCreationCheck(itemModuleOid: number): Observable<CtWebapiGenericResponse<ModuleCreationCheckResult>> {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("ModuleCreationCheck")
      .setMethod(MethodEnum.POST)
      .setQueryParams({
        hideSpinner: true
      })
      .setBody({
        itemModuleOid
      });

    return this._webapi
      .request(request);

  }

  moduleCreationRequest(parameter: ModuleCreationRequestParameter) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("ModuleCreationRequest")
      .setMethod(MethodEnum.POST)
      .setQueryParams({
        hideSpinner: true
      })
      .setBody(parameter);

    return this._webapi
      .request(request);

  }

}

export class ModuleCreationCheckResult {

  PossibileModuleQty: number | null = null;
  SingleItemsCheck: ItemHandling[] | null = null;

}

export class ModuleCreationRequestResult {

  RequestFullFilled: boolean | null = null;
  Errors: any[] | null = null;
  ModuleQty: number | null = null;
  Handlings: Handling[] | null = null;

}

export class ModuleCreationRequestParameter {

  ItemModuleOid: number | null = null;
  Qty: number | null = null;
  LocationToOid: number | null = null;
  Finalize: boolean | null = null;

  private constructor() {
  }

  public static create = (): ModuleCreationRequestParameter => new ModuleCreationRequestParameter();

  setItemModuleOid(value: number): ModuleCreationRequestParameter {

    this.ItemModuleOid = value;
    return this;

  }

  setQty(value: number): ModuleCreationRequestParameter {

    this.Qty = value;
    return this;

  }

  setLocationToOid(value: number): ModuleCreationRequestParameter {

    this.LocationToOid = value;
    return this;

  }

  setFinalize(value: boolean): ModuleCreationRequestParameter {

    this.Finalize = value;
    return this;

  }

}

import {Component} from "@angular/core";
import {BeaverImportConfiguration} from "../../layouts/beaver-import/beaver-import.configuration";
import {FileController} from "../../_core/controllers/file.controller";

@Component({
  selector: "app-item-import",
  template: `
    <beaver-import [configuration]="configuration"></beaver-import>`,
  providers: [FileController]
})
export class ItemImportComponent {

  configuration: BeaverImportConfiguration = BeaverImportConfiguration
    .create()
    .setImportSubscription((file: File) => this.fileController.itemImport(file))
    .setTitle("Importazione anagrafica articoli")
    .setSubtitle("Carica un file Excel o CSV per importare i tuoi articoli in modo rapido e preciso")
    .setDescription("Questa pagina ti consente di importare in modo massivo l'anagrafica degli articoli del tuo magazzino semplicemente caricando un file Excel o CSV. Segui le istruzioni per preparare il file correttamente e ottenere un'importazione automatica e senza errori.<br/>"
      + "In pochi clic, potrai aggiornare o completare la tua lista di prodotti e aumentare l'efficienza del tuo gestionale.<br/><br/>"
      + "Ora è possibile inserire i codici fornitori e i nomi fornitori. Abbiamo aggiunto le colonne \"SupplierCodes\" e \"SupplierNames\". "
      + "Per ogni articolo, è possibile inserire più codici fornitori separati da virgola. È importante che i codici fornitori abbiano i corrispondenti nomi fornitori. "
      + "Ad esempio, se un articolo ha 2 codici fornitori, devi inserire \"CODF1, CODF2\" nella colonna \"SupplierCodes\" e \"Fornitore 1, Fornitore 2\" nella colonna \"SupplierNames\" della stessa riga articolo.")
    .setTemplate({
      path: `assets/templates/BVSItemImportTemplate.csv`,
      fileName: `BVSItemImportTemplate${new Date().getFullYear()}`
    });

  constructor(private fileController: FileController) {
  }

}

import {Routes} from "@angular/router";
import {CtModelComponent, CtModelType} from "@ctsolution/ct-framework";
import {WorkOrderModule} from "../components/work-order/work-order.module";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {HandlingModule} from "../components/handling/handling.module";
import {WarehouseModule} from "../components/warehouse/warehouse.module";
import {CatalogModule} from "../components/catalog/catalog.module";
import {ItemImportModule} from "../components/item-import/item-import.module";
import {UserWorkingHoursModule} from "../components/user-working-hours/user-working-hours.module";
import {UserModule} from "../components/user/user.module";
import {CustomerModule} from "../components/customer/customer.module";

export const PagesRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        data: {
          title: "La tua dashboard",
          urls: [{title: "Dashboard"}]
        }
      },
      {
        path: "handling",
        loadChildren: () => HandlingModule
      },
      {
        path: "location",
        loadChildren: () => WarehouseModule
      },
      {
        path: 'user-working-hours',
        loadChildren: () => UserWorkingHoursModule
      },
      {
        path: "work-order",
        loadChildren: () => WorkOrderModule
      },
      {
        path: "catalog",
        loadChildren: () => CatalogModule
      },
      {
        path: "user",
        loadChildren: () => UserModule
      },
      {
        path: "customer",
        loadChildren: () => CustomerModule
      },
      {
        path: "ct-model",
        children: [
          {
            path: "item-import",
            loadChildren: () => ItemImportModule
          },
          {
            path: ":controller",
            children: [
              {
                path: "",
                redirectTo: "list",
                pathMatch: "full"
              },
              {
                path: "create",
                component: CtModelComponent,
                data: {type: CtModelType.FORM}
              },
              {
                path: "edit/:id",
                component: CtModelComponent,
                data: {type: CtModelType.FORM}
              },
              {
                path: "list",
                component: CtModelComponent
              },
              {
                path: "**",
                redirectTo: "list",
                pathMatch: "full"
              }
            ]
          }
        ]
      },
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full"
      }
    ]
  }
];

import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UserWorkingHours} from "../../../../_core/classes/user-working-hours";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {
  CtBinaryOperator,
  CtDateAdapterService,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelDatatableOperators,
  DeleteConfirmService
} from "@ctsolution/ct-framework";
import {delay} from "rxjs/operators";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {
  UserWorkingHoursNotesDialogComponent
} from "./user-working-hours-notes-dialog/user-working-hours-notes-dialog.component";
import {RoleService} from "../../../../_core/lib/role.service";
import {UserWorkingHoursRouteData} from "../../../../_core/route-data/user-working-hours.route-data";
import {UserWorkingHoursPicturesService} from "./user-working-hours-pictures/user-working-hours-pictures.service";

@Component({
  selector: 'app-user-working-hours-selected-list',
  templateUrl: './user-working-hours-selected-list.component.html',
  styleUrls: ['./user-working-hours-selected-list.component.scss']
})
export class UserWorkingHoursSelectedListComponent implements OnChanges {

  @Output() refresh: EventEmitter<UserWorkingHours> = new EventEmitter<UserWorkingHours>();

  @Input() currentDate: Date | null = null;
  @Input() userInfo: any | null = null;

  loading = true;
  list: Array<UserWorkingHours> = new Array<UserWorkingHours>();

  viewModel = {

    isAdministrator: false

  }

  constructor(
    private _webapi: CtWebapiService,
    private deleteConfirm: DeleteConfirmService,
    private ctDateAdapterService: CtDateAdapterService,
    private roleService: RoleService,
    private userWorkingHoursPicturesService: UserWorkingHoursPicturesService,
    private dialog: MatDialog) {

    this.setup();

  }

  async setup() {

    this.viewModel.isAdministrator = await this.roleService.is.administrator();

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes) {

      this.getActivities();

    }

  }

  remove(activity: UserWorkingHours) {

    this.deleteConfirm
      .open()
      .afterClosed()
      .subscribe((result?: boolean) => {

        if (result) {

          const request: DataRequest = new DataRequest()
            .setController([UserWorkingHoursRouteData().controller!, 'Delete'])
            .setAction((activity?.Oid ?? 0).toString()!);

          this._webapi
            .delete(request)
            .subscribe(() => this.refresh.emit());

        }

      })

  }

  notes(activity: UserWorkingHours) {

    this.dialog
      .open(UserWorkingHoursNotesDialogComponent, {
        data: UserWorkingHours.create(activity),
        minWidth: 400
      })
      .beforeClosed()
      .subscribe((refresh: boolean | null) => {

        if (refresh) this.getActivities();

      })

  }


  pictures(activity: UserWorkingHours) {

    this.userWorkingHoursPicturesService.open(activity);

  }

  getActivities() {

    if (!this.currentDate || this.viewModel.isAdministrator && !this.userInfo) {

      this.loading = false;
      return

    }

    this.loading = true;

    const body: CtModelDatatableOperators = CtModelDatatableOperators.create();

    body
      .Pagination
      ?.setElementsForPage(null);

    const filters: Array<CTModelDatatableFilter> = new Array<CTModelDatatableFilter>();

    filters
      .push(
        CTModelDatatableFilter
          .create()
          .setField('Date')
          .setValue(this.ctDateAdapterService.getTimeZoneOffsetFormat(this.currentDate))
          .setOperatorType(CtBinaryOperator.Equal)
      );

    if (this.viewModel.isAdministrator) {

      filters
        .push(
          CTModelDatatableFilter
            .create()
            .setField('UserInfo.Oid')
            .setValue(this.userInfo)
            .setOperatorType(CtBinaryOperator.Equal)
        )

    }

    body
      .setFilters(filters);

    const request: DataRequest = new DataRequest()
      .setController([UserWorkingHoursRouteData().controller!])
      .setAction('List')
      .setQueryParams({hideSpinner: true})
      .setBody(body)

    this._webapi
      .post<CtModelConfiguration<UserWorkingHours>>(request)
      .pipe(delay(500))
      .subscribe({
        next: (result: CtWebapiGenericResponse<CtModelConfiguration<UserWorkingHours>>) => {

          this.list = result.Result.DataSource as Array<UserWorkingHours> ?? [];
          this.loading = false

        },
        error: () => {

          this.list = [];
          this.loading = false

        }
      })


  }

}

import {
  CtControlConfiguration,
  CtControlTypes
} from "@ctsolution/ct-framework";

export const LocationToControl: CtControlConfiguration = <CtControlConfiguration>{
  name: "LocationToOid",
  label: "Deposito Destinazione",
  type: CtControlTypes.ENUMERABLE,
  validators: [
    {name: "required"}
  ]
};

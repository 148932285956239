<div [class.mat-dialog-content]="!!dialogConfiguration">

  <mat-card class="userworkinghours-wizard-container">

    <mat-card-header>

      <mat-card-title fxLayout="row">
        <div>Registra le tue ore</div>
        <div fxFlex></div>
        <div>
          <button mat-dialog-close mat-mini-fab *ngIf="dialogConfiguration?.closeButtonEnabled">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-card-title>

      <mat-card-subtitle>

        Inserisci le tue ore per tenere traccia delle tue attività e garantire una corretta gestione del tuo tempo di
        lavoro.

      </mat-card-subtitle>

    </mat-card-header>

    <mat-card-content>

      <div
        class="m-b-10"
        fxLayout="row"
        fxLayoutAlign="space-between center">

        <button [disabled]="dialogConfiguration?.data" mat-icon-button (click)="updateDate(DateUpdateState.previous)">
          <mat-icon>chevron_left</mat-icon>
        </button>

        <span [@highlightAnimation]="highlighted"
              (@highlightAnimation.done)="animationDone($event)"> {{ currentDate?.toLocaleDateString() }} </span>

        <button [disabled]="dialogConfiguration?.data" mat-icon-button (click)="updateDate(DateUpdateState.next)">
          <mat-icon>chevron_right</mat-icon>
        </button>

      </div>

      <app-user-working-hours-wizard-form
        #UserWorkingHoursWizardForm
        [currentDate]="currentDate"
        (refresh)="selectedList.getActivities()"></app-user-working-hours-wizard-form>

    </mat-card-content>

  </mat-card>

  <app-user-working-hours-selected-list
    #selectedList
    [currentDate]="currentDate"
    [userInfo]="UserWorkingHoursWizardForm.form.value.UserInfo"
    (refresh)="selectedList.getActivities()"></app-user-working-hours-selected-list>

</div>

import { TemplateRef } from "@angular/core";

export class BeaverHeaderConfiguration {

  get actionsTemplate(): TemplateRef<any> | null {
    return this._actionsTemplate;
  }

  private set actionsTemplate(value: TemplateRef<any> | null) {
    this._actionsTemplate = value;
  }

  get title(): string | null {
    return this._title;
  }

  private set title(value: string | null) {
    this._title = value;
  }

  private _title: string | null = null;
  private _actionsTemplate: TemplateRef<any> | null = null;

  private constructor() {
  }

  public static create = (): BeaverHeaderConfiguration => new BeaverHeaderConfiguration();

  setTitle(value: string | null): BeaverHeaderConfiguration {

    this.title = value;
    return this;

  }

  setTemplateRef(value: TemplateRef<any> | null): BeaverHeaderConfiguration {

    this.actionsTemplate = value;
    return this;
    
  }

}

<h1 mat-dialog-title> {{ data.Name }}</h1>
<div mat-dialog-content>

  <p>

    Per la categoria Gelo mancano i seguenti prodotti:

  </p>

  <ul>

    <li *ngFor="let item of GELO"> {{ item.qta }} x {{ item.name }}</li>

  </ul>

  <p>

    Per la categoria Fresco mancano i seguenti prodotti:

  </p>

  <ul>

    <li *ngFor="let item of FRESCO"> {{ item.qta }} x {{ item.name }}</li>

  </ul>


  <p>

    Per la categoria Secco mancano i seguenti prodotti:

  </p>

  <ul>

    <li *ngFor="let item of SECCO"> {{ item.qta }} x {{ item.name }}</li>

  </ul>

</div>
<div
  mat-dialog-actions
  fxLayout="row wrap"
  fxLayoutAlign="space-between center">
  <button mat-button color="warn" (click)="close()">Chiudi</button>
</div>

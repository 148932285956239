import {TableColumn} from "@swimlane/ngx-datatable";

export const ITEM_SELECTION_COLUMNS: TableColumn[] = [
  {
    prop: "Code",
    name: "Codice"
  },
  {
    prop: "Name",
    name: "Nome"
  },
  {
    prop: "Description",
    name: "Descrizione",
    width: 300
  },
  {
    prop: "DefaultUnitOfMeasure.Symbol",
    name: "UM"
  }
];

<div
  class="handling-create-cntr">

  <app-handling-generic-info
    (updateState)="submit($event)"
    #genericInfo></app-handling-generic-info>

  <mat-divider></mat-divider>

  <app-item-search
    *ngIf="genericInfo.handlingIsInDraftStatus"
    [configuration]="itemSelectionConfiguration"
    (onFocus)="genericInfo.closeAccordion()"
    (onSelected)="itemSelectionService.updateSelection($event, selectedItemsConfiguration)"
    (onImport)="itemSelectionService.setupItemHandlings($event, selectedItemsConfiguration)"
    (invalidForm)="genericInfo.form.markAllAsTouched()"></app-item-search>

  <mat-divider></mat-divider>

  <app-selected-items
    (onCancel)="general.back()"
    [class.invalid]="genericInfo.form.touched && !selectedItemsConfiguration.selected.length"
    (onSubmit)="submit()"
    [configuration]="selectedItemsConfiguration"></app-selected-items>

</div>

import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ItemSelectionComponent} from "./item-selection/item-selection.component";
import {ItemSelectionConfiguration} from "./item-selection/item-selection.configuration";
import {SelectedItemRowConfiguration} from "../selected-items/selected-item-row/selected-item-row.configuration";
import {SelectedItemsConfiguration} from "../selected-items/selected-items.configuration";
import {BeaverItemStock} from "../../../../../_core/classes/beaver-item-stock";
import {ItemHandling} from "../../../../../_core/classes/item-handling";

@Injectable()
export class ItemSelectionService {

  constructor(private dialog: MatDialog) {
  }

  open = (data: ItemSelectionConfiguration | null = null) => this.dialog.open(ItemSelectionComponent, {
    disableClose: true,
    data,
    minWidth: "65vw"
  });

  updateSelection(event: BeaverItemStock[] = [], selectedItemsConfiguration: SelectedItemsConfiguration) {

    selectedItemsConfiguration
      .selectItems(event);

  }

  setupItemHandlings(value: ItemHandling[] = [], selectedItemsConfiguration: SelectedItemsConfiguration) {

    const itemHandlings: SelectedItemRowConfiguration[] = value
      .map((item: ItemHandling) => {

        const itemHandling: ItemHandling = ItemHandling.create(item);

        return SelectedItemRowConfiguration
          .create()
          .setBeaverItemStock(BeaverItemStock.createByBeaverItem(itemHandling.Item))
          .setQtyValue(itemHandling.Qty)
          .setStartQtyValue(itemHandling.StartQty)
          .setUMValue(itemHandling.UM)
          .setItemCategoryTrackEnabled(item.ItemCategoryTrackEnable);

      });

    selectedItemsConfiguration
      .setSelected(itemHandlings);

  }

}

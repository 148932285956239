import {Component, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {
  CtControlConfiguration, CtControlValidator,
  CtModelConfiguration,
  CtSelectControlValue,
  CtThemeTypes, SnackbarService
} from "@ctsolution/ct-framework";
import {LocationFromControl, SupplierFromControl} from "./_forms/location-from.form-configuration";
import {DocumentNotesControl, InternalNotesControl} from "./_forms/notes.form-configuration";
import {LocationToControl} from "./_forms/location-to.form-configuration";
import {MatAccordion} from "@angular/material/expansion";
import {ActivatedRoute} from "@angular/router";
import {HandlingService} from "../../../handling.service";
import {LocationController} from "../../../../../_core/controllers/location.controller";
import {SupplierController} from "../../../../../_core/controllers/supplier.controller";
import {HandlingStateEnum} from "../../../../../_core/enum/handling-state.enum";
import {HandlingOriginEnum} from "../../../../../_core/enum/handling-origin.enum";
import {Handling} from "../../../../../_core/classes/handling";
import {BeaverLocation} from "../../../../../_core/classes/beaver-location";
import {DataSourceListElement} from "../../../../../_core/controllers/controller.helper";
import {BeaverSupplier} from "../../../../../_core/classes/beaver-supplier";

@Component({
  selector: "app-handling-generic-info",
  templateUrl: "./handling-generic-info.component.html",
  styleUrls: ["./handling-generic-info.component.scss"],
  providers: [LocationController, SupplierController]
})
export class HandlingGenericInfoComponent implements OnInit {

  @ViewChild(MatAccordion) accordion?: MatAccordion;
  @Output() updateState: EventEmitter<HandlingStateEnum> = new EventEmitter<HandlingStateEnum>();

  form: FormGroup;

  //#region Controls
  locationFromControl: CtControlConfiguration = CtControlConfiguration
    .create(LocationFromControl)
    .setTheme(CtThemeTypes.MATERIAL);

  supplierFromControl: CtControlConfiguration = CtControlConfiguration
    .create(SupplierFromControl)
    .setTheme(CtThemeTypes.MATERIAL);

  locationToControl: CtControlConfiguration = CtControlConfiguration
    .create(LocationToControl)
    .setTheme(CtThemeTypes.MATERIAL);

  internalNotesControl: CtControlConfiguration = CtControlConfiguration
    .create(InternalNotesControl)
    .setTheme(CtThemeTypes.MATERIAL);

  documentNotesControl: CtControlConfiguration = CtControlConfiguration
    .create(DocumentNotesControl)
    .setTheme(CtThemeTypes.MATERIAL);

  //#endregion Controls
  constructor(
    private formBuilder: FormBuilder,
    private locationController: LocationController,
    private supplierController: SupplierController,
    private route: ActivatedRoute,
    private _snackbar: SnackbarService,
    private handlingService: HandlingService) {

    this.form = this.formBuilder.group({
      Oid: new FormControl(null),
      SystemCreated: new FormControl(false),
      HandlingOrigin: new FormControl(HandlingOriginEnum.Location),
      HandlingState: new FormControl(HandlingStateEnum.Draft),
      Number: new FormControl(null)
    });

    this.locationFromControl
      .setFormParent(this.form);

    this.supplierFromControl
      .setFormParent(this.form);

    this.locationToControl
      .setFormParent(this.form);

    this.internalNotesControl
      .setFormParent(this.form);

    this.documentNotesControl
      .setFormParent(this.form);

    this.route
      .queryParams
      .subscribe(
        qp => {

          if (qp["startFrom"]) {

            this.locationFromControl
              .setValue(+qp["startFrom"]);

          }

        });

  }

  get handlingOriginSupplier(): boolean {

    return this.form.get("HandlingOrigin")?.value === HandlingOriginEnum.Supplier;

  }

  get handlingIsInDraftStatus(): boolean {

    return this.form.get("HandlingState")?.value === HandlingStateEnum.Draft;

  }

  get handlingIsDeliveredStatus(): boolean {

    return this.form.get("HandlingState")?.value === HandlingStateEnum.Delivered;

  }

  get nextStep(): { label: string, value: HandlingStateEnum } | null {

    const deliveredState = {label: "Conclusa", value: HandlingStateEnum.Delivered};

    switch (this.form.get("HandlingState")?.value) {

      case HandlingStateEnum.Draft:
        return {label: "In transito", value: HandlingStateEnum.OnTheWay};

      case HandlingStateEnum.OnTheWay:

        if (this.handlingOriginSupplier) return {label: "Conferma quantità", value: HandlingStateEnum.CheckIn};

        return deliveredState;

      case HandlingStateEnum.CheckIn:
        return deliveredState;

      case HandlingStateEnum.Delivered:
      default:
        return null;

    }

  }

  ngOnInit() {

    this.setupLocations();
    this.setupSuppliers();

  }

  downloadPdf(event?: any) {

    event?.stopPropagation();

    if (!this.form.value.Oid) return;

    this.handlingService.downloadHandlingPDF(this.form.value.Oid)

  }

  ngAfterViewInit() {

    this.setupOrigin();

  }

  changeState(event?: any) {

    event?.stopPropagation();

    this.updateState.emit(this.nextStep?.value);

  }

  setupOrigin() {


    const originPathValue: string | null = this.route.snapshot.paramMap.get("origin");
    const origin: HandlingOriginEnum = this.handlingService.getHandlingTypeByRouteValue(originPathValue);

    this.form.get("HandlingOrigin")?.setValue(origin);
    this.form.get("HandlingOrigin")?.updateValueAndValidity();

    setTimeout(() => {

      this.locationFromControl
        .setValidators(this.handlingOriginSupplier ? null : [CtControlValidator.create({name: "required"} as CtControlValidator)]);

      this.locationFromControl.control?.updateValueAndValidity();

      this.supplierFromControl
        .setValidators(this.handlingOriginSupplier ? [CtControlValidator.create({name: "required"} as CtControlValidator)] : null);

      this.supplierFromControl.control?.updateValueAndValidity();

    });

  }

  setupValue(value: Handling) {

    this.form.patchValue(value);

    if (value.SupplierFrom && this.supplierFromControl) {

      this.setupLookupValue(this.supplierFromControl, value.SupplierFrom.Oid);

    }

    if (value.LocationFrom && this.locationFromControl) {

      this.setupLookupValue(this.locationFromControl, value.LocationFrom.Oid);

    }

    if (value.LocationTo && this.locationToControl) {

      this.setupLookupValue(this.locationToControl, value.LocationTo.Oid);

    }

    if (this.handlingIsDeliveredStatus) {

      this.form.disable();

    } else if (!this.handlingIsInDraftStatus) {

      this.supplierFromControl
        .setDisabled(true);

      this.locationFromControl
        .setDisabled(true);

      this.locationToControl
        .setDisabled(true);

    }

  }

  setupLookupValue(lookup: CtControlConfiguration, value: any) {

    const optionValue: CtSelectControlValue | undefined = lookup
      ?.valueOptions
      ?.find(option => option.value === value);

    if (!optionValue) return;

    lookup
      .setValue(optionValue.value);

  }

  private setupLocations() {

    this.locationController
      .getEnabledLocations()
      .then(enabledLocations => {

        //filtro le location di carico abilitate
        const loadLocations: Array<BeaverLocation> = enabledLocations.filter(elm => elm.LoadPermitted);

        this.locationToControl
          .setValueOptions(this.getCtSelectControlValue(loadLocations));

        if (!this.handlingOriginSupplier) {

          //filtro le location di scarico abilitate
          const unloadLocations: Array<BeaverLocation> = enabledLocations.filter(elm => elm.UnloadPermitted);

          this.locationFromControl
            .setValueOptions(this.getCtSelectControlValue(unloadLocations));

          this.preventSingleDepositMovement();

        }

      });

  }

  private preventSingleDepositMovement() {
    const check = (CTControl: CtControlConfiguration, value: any) => {

      if (!CTControl.control?.value ?? !value) return;
      if (CTControl.control?.value !== value) return;

      CTControl.setValue(null);
      this._snackbar.open("Non è possibile spostare le merci nella stessa posizione del magazzino");

    };

    const locationFromControlValueChanges = this.locationFromControl.control?.valueChanges;
    const locationToControlValueChanges = this.locationToControl.control?.valueChanges;

    locationFromControlValueChanges?.subscribe(() => check(this.locationFromControl, this.locationToControl.control?.value));
    locationToControlValueChanges?.subscribe(() => check(this.locationToControl, this.locationFromControl.control?.value));

  }

  private setupSuppliers() {

    this.supplierController
      .get()
      .then((result: CtModelConfiguration<Array<DataSourceListElement>>) => {

        const enabledSupplier: Array<BeaverSupplier> = (<BeaverSupplier[]>(result.DataSource ?? []))
          .filter(elm => elm.IsActive);

        this.supplierFromControl
          .setValueOptions(this.getCtSelectControlValue(enabledSupplier));

      });

  }

  private getCtSelectControlValue = (list: Array<DataSourceListElement | BeaverLocation>): CtSelectControlValue[] => list.map((elm) => CtSelectControlValue.create()
    .setLabel(elm.Name)
    .setValue(elm.Oid?.toString()));

  closeAccordion() {

    this.accordion?.closeAll();

  }

  openAccordion() {

    this.accordion?.openAll();

  }

}

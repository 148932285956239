import { HandlingOriginEnum } from "../enum/handling-origin.enum";
import { HandlingStateEnum } from "../enum/handling-state.enum";
import { ItemHandling } from "./item-handling";
import { BeaverLocation } from "./beaver-location";
import { DataSourceListElement } from "../controllers/controller.helper";
import { CTBase } from "@ctsolution/ct-framework";

export class Handling extends CTBase<Handling> {

  Oid: number | null = null;
  HandlingOrigin: HandlingOriginEnum | null = null;
  HandlingState: HandlingStateEnum | null = null;
  Number: number | null = null;
  InternalNotes: string | null = null;
  DocumentNotes: string | null = null;
  LocationFrom: BeaverLocation | null = null;
  LocationTo: BeaverLocation | null = null;
  SupplierFrom: DataSourceListElement | null = null;
  ItemHandlings: ItemHandling[] | null = null;

  private constructor();
  private constructor(model?: Handling);
  private constructor(model?: Handling) {

    super();

    if (model) {

      this.getClass(model);

    }

  }

  public static create = (model?: Handling): Handling => new Handling(model);

  setLocationFrom(Oid: number | null): Handling {

    this.LocationFrom = { Oid } as BeaverLocation;
    return this;

  }

  setLocationTo(Oid: number | null): Handling {

    this.LocationTo = { Oid } as BeaverLocation;
    return this;

  }

  setSupplierFrom(Oid: number | null): Handling {

    this.SupplierFrom = { Oid } as DataSourceListElement;
    return this;

  }

  setItemHandlings(value: ItemHandling[] | null): Handling {

    this.ItemHandlings = value;
    return this;

  }

  setHandlingState(value: HandlingStateEnum | null): Handling {

    this.HandlingState = value;
    return this;

  }

}

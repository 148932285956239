import {CtControlConfiguration, CtControlTypes} from "@ctsolution/ct-framework";

export const InternalNotesControl: CtControlConfiguration = <CtControlConfiguration>{
  name: "InternalNotes",
  label: "Note Interne",
  type: CtControlTypes.TEXTAREA
};

export const DocumentNotesControl: CtControlConfiguration = <CtControlConfiguration>{
  name: "DocumentNotes",
  label: "Note Documento",
  type: CtControlTypes.TEXTAREA
};

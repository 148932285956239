import {CTBase} from "@ctsolution/ct-framework";

export class UserWorkingHours extends CTBase<UserWorkingHours> {

  Oid: number | null = null;
  UserInfo: { Oid: number | null } | null = null;
  WorkOrder: { Oid: number | null; Name?: string | null; } | null = null;
  DictionaryInfo: { Oid: number | null; Name?: string | null; } | null = null;
  ActivityType: { Oid: number | null; Name?: string | null; } | null = null;
  Hours: number | null = null;
  Date: Date | null = null;
  Note: string | null = null;

  private constructor();
  private constructor(model?: UserWorkingHours);
  private constructor(model?: UserWorkingHours) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: UserWorkingHours) => new UserWorkingHours(model);

  setDate(value: Date | null): UserWorkingHours {

    this.Date = value;
    return this;

  }

  setHours(value: number | null): UserWorkingHours {

    this.Hours = value;
    return this;

  }

  setUserInfo(value: number | null): UserWorkingHours {

    this.UserInfo = {

      Oid: value

    }

    return this;

  }

  setWorkOrder(value: number | null): UserWorkingHours {

    this.WorkOrder = {

      Oid: value

    }

    return this;

  }

  setDictionaryInfo(value: number | null): UserWorkingHours {

    this.DictionaryInfo = {

      Oid: value

    }

    return this;

  }

  setActivityType(value: number | null): UserWorkingHours {

    this.ActivityType = {

      Oid: value

    }

    return this;

  }

  setNotes(value: string | null): UserWorkingHours {

    this.Note = value;
    return this;

  }

}

import {Component, EventEmitter, Input, Output, ViewChildren} from "@angular/core";
import {CtButtonConfiguration, MAT_RAISED_ACCENT, MAT_RAISED_PRIMARY, MAT_RAISED_WARN} from "@ctsolution/ct-framework";
import {SelectedItemsConfiguration} from "./selected-items.configuration";
import {SelectedItemRowConfiguration} from "./selected-item-row/selected-item-row.configuration";
import {SelectedItemRowComponent} from "./selected-item-row/selected-item-row.component";
import {HandlingStateEnum} from "../../../../../_core/enum/handling-state.enum";
import {environment} from "../../../../../../environments/environment";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {BarcodeTrackingComponent} from "./barcode-tracking/barcode-tracking.component";

@Component({
  selector: "app-selected-items",
  templateUrl: "./selected-items.component.html",
  styleUrls: ["./selected-items.component.scss"]
})
export class SelectedItemsComponent {

  @Input() configuration: SelectedItemsConfiguration | null = null;

  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(SelectedItemRowComponent) selectedItemRowComponents?: SelectedItemRowComponent[];

  get handlingItemActionsEnabled(): boolean {

    return ![HandlingStateEnum.Delivered, HandlingStateEnum.OnTheWay].includes(this.configuration?.handlingState ?? HandlingStateEnum.Draft);

  }

  get handlingCheckIn(): boolean {

    return this.configuration?.handlingState === HandlingStateEnum.CheckIn;

  }

  get barcodeTrackingCheckInEnabled(): boolean {

    return this.itemsWithRequiredTracking.length > 0 && this.handlingCheckIn;

  }

  get itemsWithRequiredTracking(): SelectedItemRowConfiguration[] {

    return this.configuration?.selected.filter(item => item.itemCategoryTrackEnabled) ?? [];

  }

  get handlingDelivered(): boolean {

    return this.configuration?.handlingState === HandlingStateEnum.Delivered;

  }

  constructor(private dialog: MatDialog) {
  }

  barcodeTrackingButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Tracciatura barcode")
    .setAction(() => this.openBarcodeTracking())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Indietro")
    .setAction(() => this.onCancel.emit())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setAction(() => {

      this.selectedItemRowComponents
        ?.forEach(item => item.form.markAllAsTouched());

      const invalidForm = this.selectedItemRowComponents?.find(item => item.form.invalid);
      if (invalidForm) return;

      this.onSubmit.emit();

    })
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  delete(row: SelectedItemRowConfiguration) {

    const idx: number = (this.configuration?.selected ?? []).indexOf(row);

    if (idx > -1) {

      (this.configuration?.selected ?? []).splice(idx, 1);

    }

  }

  openBarcodeTracking(selected: SelectedItemRowConfiguration | null = null) {

    const data: any = {
      items: this.itemsWithRequiredTracking
    }

    if (selected) {

      data.selected = selected;

    }

    this.dialog.open(BarcodeTrackingComponent, {
      data,
      panelClass: 'barcode-tracking-panel',
      minWidth: '400px'
    });

  }

}

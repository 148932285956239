import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {WarehouseQrComponent} from "./warehouse-detail/warehouse-qr/warehouse-qr.component";
import {ReportController} from "../../_core/controllers/report.controller";
import {LocationMacroType} from "../../_core/enum/location.macro-type";

@Injectable()
export class WarehouseService {
  constructor(
    private dialog: MatDialog,
    private reportController: ReportController) {
  }

  openQRCodeFromLocation(data: { locationOid: number, locationName?: string }) {

    this.dialog
      .open(WarehouseQrComponent, {data});

  }

  downloadLocationPDF(locationOid: number) {

    this.reportController
      .getLocationPDF(locationOid, true)
      .subscribe();

  }

  getLocationMacroTypeByRouteValue(macroTypePathValue: string | null): LocationMacroType | null {

    switch (macroTypePathValue) {

      case "box":
        return LocationMacroType.Box;
      case "sorting-areas":
        return LocationMacroType.Temporary;
      case "stores":
      default:
        return LocationMacroType.Standard;

    }

  }

  getLocationRouteValueByMacroType(value: LocationMacroType | null): string {

    switch (value) {

      case LocationMacroType.Box:
        return "box";
      case LocationMacroType.Temporary:
        return "sorting-areas";
      case LocationMacroType.Standard:
      default:
        return "stores";

    }

  }

}

import {DataRequest} from "@ctsolution/ct-webapi";
import {Injectable} from "@angular/core";
import {CtModelConfiguration, CTModelDatatableFilter} from "@ctsolution/ct-framework";
import {ControllerHelper} from "./controller.helper";
import {Handling} from "../classes/handling";

const CONTROLLER: string[] = ["handling"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class HandlingController {

  constructor(private _helper: ControllerHelper) {
  }

  get = (filters: CTModelDatatableFilter[]): Promise<CtModelConfiguration<Array<Handling>>> => this._helper.list(CONTROLLER_REQUEST()
    .setBody({
      Filters: filters
    }));

}

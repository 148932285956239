<div
  class="module-add-item-cntr">

  <div
    mat-dialog-content>
    <app-item-search
      [configuration]="itemSelectionConfiguration"
      (onSelected)="itemSelectionService.updateSelection($event, selectedItemsConfiguration)"
      (onImport)="itemSelectionService.setupItemHandlings($event, selectedItemsConfiguration)"></app-item-search>

    <mat-divider></mat-divider>

    <app-selected-items
      (onCancel)="onCancel()"
      (onSubmit)="submit()"
      [configuration]="selectedItemsConfiguration"></app-selected-items>

  </div>

</div>

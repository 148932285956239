import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerEditComponent} from "./customer-edit.component";
import {CtModelModule} from "@ctsolution/ct-framework";
import {MatListModule} from "@angular/material/list";

@NgModule({
    declarations: [
        CustomerEditComponent
    ],
    imports: [
        CommonModule,
        CtModelModule,
        MatListModule
    ]
})
export class CustomerEditModule {
}

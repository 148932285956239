import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CtModelModule} from "@ctsolution/ct-framework";
import {CustomerListComponent} from "./customer-list.component";

@NgModule({
  declarations: [
    CustomerListComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ]
})
export class CustomerListModule {
}

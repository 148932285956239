import {Component} from "@angular/core";
import {
  CtBinaryOperator,
  CtControlValidator,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelRouteData,
  CtModelType
} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {WarehouseService} from "../warehouse.service";
import {WhListParameter} from "../warehouse-list/warehouse-list.component";
import {CtFieldCustomColumn} from "@ctsolution/ct-framework/lib/ct-datatable/_core/ct-field-custom.column";
import {LocationMacroType} from "../../../_core/enum/location.macro-type";
import {WarehouseRouteData} from "../../../_core/route-data/warehouse.route-data";

@Component({
  selector: "app-warehouse-edit",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class WarehouseEditComponent {

  routeData: CtModelRouteData = WarehouseRouteData()
    .setModelType(CtModelType.FORM);
  configuration: CtModelConfiguration<WarehouseEditComponent> | null = null;

  constructor(private route: ActivatedRoute, private whService: WarehouseService) {
  }

  ngAfterViewInit() {

    this.constantType()
      .then((parameter: WhListParameter) => {

        const pId: string | null = this.route.snapshot.paramMap.get("id");

        if (pId) this.routeData.setId(+pId);

        const configuration = CtModelConfiguration
          .create<WarehouseEditComponent>()
          .setRouteData(this.routeData);

        const isBoxTemplate = parameter.MacroType === LocationMacroType.Box;

        let sizeValidators: CtControlValidator[] | undefined = undefined;

        if (isBoxTemplate) {

          sizeValidators = [
            CtControlValidator.create({name: 'max', value: 999999} as CtControlValidator),
            CtControlValidator.create({name: 'min', value: 0} as CtControlValidator)];

        }

        const fieldsCustomTemplates: CtFieldCustomColumn[] = [
          {prop: "ParentLocation", visible: isBoxTemplate, required: isBoxTemplate},
          {prop: "WorkOrder", visible: !isBoxTemplate, disabled: isBoxTemplate},
          {prop: "BoxWeight", visible: isBoxTemplate, validators: sizeValidators},
          {prop: "BoxLength", visible: isBoxTemplate, validators: sizeValidators},
          {prop: "BoxHeight", visible: isBoxTemplate, validators: sizeValidators},
          {prop: "BoxWidth", visible: isBoxTemplate, validators: sizeValidators}
        ];

        if (parameter.MacroType || parameter.MacroType === LocationMacroType.Standard) {

          configuration
            .setFilterValues([
              CTModelDatatableFilter
                .create()
                .setField('MacroType')
                .setValue(parameter.MacroType)
                .setOperatorType(CtBinaryOperator.Equal)
            ]);

          fieldsCustomTemplates
            .push({prop: "MacroType", disabled: true, visible: false});

        }

        configuration
          .setFieldsCustomTemplates(fieldsCustomTemplates);

        this.configuration = configuration;

      });

  }


  constantType(): Promise<WhListParameter> {

    return new Promise<WhListParameter>((resolve) => {

      const macroTypePathValue: string | null = this.route.snapshot.paramMap.get("macrotype");
      const returnValue: WhListParameter = new WhListParameter(this.whService.getLocationMacroTypeByRouteValue(macroTypePathValue));

      this
        .route
        .data
        .subscribe(data => {

          switch (returnValue.MacroType) {

            case LocationMacroType.Temporary:
              data["title"] = "Aree di smistamento";
              break;
            case LocationMacroType.Box:
              data["title"] = "Scatole";
              break;
            default:
              data["title"] = "Magazzini";
              break;
          }

          resolve(returnValue);

        });

    });

  }

}

import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {
  CtButtonConfiguration,
  CtControlConfiguration,
  CtThemeTypes, MAT_RAISED_ACCENT,
  MAT_RAISED_PRIMARY
} from "@ctsolution/ct-framework";
import {ItemSelectionService} from "../item-selection.service";
import {ItemSelectionConfiguration} from "../item-selection/item-selection.configuration";
import {ItemSearchControl} from "./search.form-configuration";
import {ItemSelectionImportService} from "../item-selection-import/item-selection-import.service";
import {BeaverItemStock} from "../../../../../../_core/classes/beaver-item-stock";
import {ItemHandling} from "../../../../../../_core/classes/item-handling";

@Component({
  selector: "app-item-search",
  templateUrl: "./item-search.component.html",
  styleUrls: ["./item-search.component.scss"]
})
export class ItemSearchComponent {

  form: FormGroup;

  @Input() configuration: ItemSelectionConfiguration | null = null;

  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onImport: EventEmitter<ItemHandling[]> = new EventEmitter<ItemHandling[]>();
  @Output() onSelected: EventEmitter<BeaverItemStock[]> = new EventEmitter<BeaverItemStock[]>();
  @Output() invalidForm: EventEmitter<any> = new EventEmitter<any>();

  itemSearchControl: CtControlConfiguration = CtControlConfiguration
    .create(ItemSearchControl)
    .setTheme(CtThemeTypes.MATERIAL);

  searchButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Ricerca")
    .setAction(() => this.onSearch())
    .setMatherialOptions(MAT_RAISED_PRIMARY);


  importButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Importa")
    .setAction(() => this.import())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  constructor(private formBuilder: FormBuilder, private itemSelectionService: ItemSelectionService, private itemSelectionImportService: ItemSelectionImportService) {

    this.form = this.formBuilder.group({});

    this.itemSearchControl
      .setFormParent(this.form);

  }

  closeInfoAccordion() {

    if (!this.configuration?.from) {

      this.invalidForm.emit();
      return;

    }

    this.onFocus.emit();

  }

  onSearch() {

    this.form.markAllAsTouched();

    if (!this.configuration?.from) {

      this.invalidForm.emit();
      return;

    }

    if (this.form.valid) {

      this.configuration
        .setSearchText(this.form.value.itemSearch);

    }

    this.itemSelectionService
      .open(this.configuration)
      .afterClosed()
      .subscribe((event: BeaverItemStock[] | null) => {

        if (event) {

          this.onSelected.emit(event);

        }

      });

  }

  import() {

    this.itemSelectionImportService
      .open()
      .afterClosed()
      .subscribe((result: ItemHandling[] | null) => {

        if (result) {

          this.onImport.emit(result);

        }

      });

  }

}

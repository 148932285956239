import {ChangeDetectorRef, Component, Input} from "@angular/core";
import {BeaverHeaderConfiguration} from "./beaver-header.configuration";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";

@Component({
  selector: "beaver-header",
  templateUrl: "./beaver-header.component.html",
  styleUrls: ["./beaver-header.component.scss"]
})
export class BeaverHeaderComponent {

  @Input() configuration: BeaverHeaderConfiguration | null = null;

  constructor(private title: Title, private cdr: ChangeDetectorRef, private route: ActivatedRoute) {
  }

  ngAfterViewInit() {

    this.title.setTitle(this.configuration?.title ?? environment.projectName!);

    if (this.configuration?.title) {

      this
        .route
        .data
        .subscribe(
          data => {

            data["title"] = this.configuration?.title;
            data["urls"] = [];

          });

    }

    this.cdr.detectChanges();

  }

}

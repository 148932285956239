import {DataRequest} from "@ctsolution/ct-webapi";
import {Injectable} from "@angular/core";
import {ControllerHelper} from "./controller.helper";
import {CTModelDatatableFilter, CtSelectControlValue} from "@ctsolution/ct-framework";
import {UnitsOfMeasurements} from "../classes/units-of-measurements";

const CONTROLLER: string[] = ["unitsofmeasurements"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class UnitsOfMeasurementController {

  UMListSelectControlValues: Array<CtSelectControlValue> = new Array<CtSelectControlValue>();
  loadingUM = false;

  constructor(private _helper: ControllerHelper) {
  }

  get = (filters?: CTModelDatatableFilter[], attempt = 3): Promise<Array<CtSelectControlValue>> => {

    return new Promise<Array<CtSelectControlValue>>((resolve) => {

      if (this.UMListSelectControlValues.length || attempt <= 0) {

        resolve(this.UMListSelectControlValues);
        return;

      }

      if (this.loadingUM) {

        setTimeout(() => resolve(this.get(filters, --attempt)), 400);
        return;

      }

      this.loadingUM = true;

      this._helper.list(CONTROLLER_REQUEST()
        .setBody({
          Filters: filters ?? []
        }))
        .then(response => {

          this.loadingUM = false;

          this.setupUMControlValues(response.DataSource as Array<UnitsOfMeasurements> ?? []);
          resolve(this.UMListSelectControlValues);
          return;

        });

    });

  };

  private setupUMControlValues(list: Array<UnitsOfMeasurements>) {

    this.UMListSelectControlValues = list
      .map((elm: UnitsOfMeasurements) => CtSelectControlValue
        .create()
        .setLabel(elm.Symbol)
        .setValue(elm.Oid));

  }

}

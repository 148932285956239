import {Injectable} from "@angular/core";
import {ReportController} from "../../_core/controllers/report.controller";
import {HandlingOriginEnum} from "../../_core/enum/handling-origin.enum";

@Injectable()
export class HandlingService {

  constructor(private reportController: ReportController) {
  }

  downloadHandlingPDF(handlingOid: number) {

    this.reportController
      .getHandlingPDF(handlingOid, true)
      .subscribe();

  }

  getHandlingTypeByRouteValue(originPathValue: string | null): HandlingOriginEnum {

    switch (originPathValue) {

      case "order":
        return HandlingOriginEnum.Supplier;
      case "movement":
      default:
        return HandlingOriginEnum.Location;

    }

  }

}

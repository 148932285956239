import {DataRequest} from "@ctsolution/ct-webapi";
import {Injectable} from "@angular/core";
import {ControllerHelper} from "./controller.helper";
import {
  CtModelConfiguration,
  CtModelDatatableOperators
} from "@ctsolution/ct-framework";
import {BeaverItemStock} from "../classes/beaver-item-stock";

const CONTROLLER: string[] = ["locationstock"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class LocationStockController {

  constructor(private _helper: ControllerHelper) {
  }

  get = (operators: CtModelDatatableOperators): Promise<CtModelConfiguration<Array<BeaverItemStock>>> => this._helper
    .list(CONTROLLER_REQUEST()
      .setBody(operators));

}

import {CtSerializerHelperService, CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {Injectable} from "@angular/core";
import {ControllerHelper} from "./controller.helper";
import {CtModelConfiguration, CTModelDatatableFilter} from "@ctsolution/ct-framework";
import {BeaverFile} from "../classes/beaver-file";

const CONTROLLER: string[] = ["UserWorkingHoursFile"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class UserWorkingHoursFileController {

  constructor(
    private _webapi: CtWebapiService,
    private serializerHelperService: CtSerializerHelperService,
    private _helper: ControllerHelper) {
  }

  get = <T>(filters: CTModelDatatableFilter[]): Promise<CtModelConfiguration<Array<BeaverFile>>> => this._helper.list(CONTROLLER_REQUEST()
    .setBody({
      Filters: filters
    }));


  create(file: BeaverFile) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction('Create')
      .setBody(this.serializerHelperService.objectToFormData(file))
      .setMethod(MethodEnum.POST);

    return this._webapi
      .request(request);

  }

  delete(file: BeaverFile) {

    if (!file.Oid) return;

    const request: DataRequest = new DataRequest()
      .setController([...CONTROLLER, 'DeleteByOid'])
      .setAction(file.Oid.toString())
      .setMethod(MethodEnum.DELETE);

    return this._webapi
      .request(request);

  }

}

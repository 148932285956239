import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ModuleAddItemComponent} from "./module-add-item.component";
import {ModuleAddItemService} from "./module-add-item.service";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {
  ItemSelectionModule
} from "../../../../handling/handling-create/_components/item-selection/item-selection.module";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {
  SelectedItemsModule
} from "../../../../handling/handling-create/_components/selected-items/selected-items.module";
import {UnitsOfMeasurementController} from "../../../../../_core/controllers/unitsofmeasurements.controller";

@NgModule({
  declarations: [
    ModuleAddItemComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ItemSelectionModule,
    MatListModule,
    SelectedItemsModule
  ],
  providers: [
    ModuleAddItemService,
    UnitsOfMeasurementController
  ]
})
export class ModuleAddItemModule {
}

import {Component} from '@angular/core';
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelOnFlyCreateService,
  CtModelRouteData,
  CtModelType
} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {CUSTOMER_ROUTEDATA, LocationRouteData} from "../customer.module";
import {BeaverLocation} from "../../../_core/classes/beaver-location";

@Component({
  selector: 'app-customer-edit',
  template: `

    <ct-model *ngIf="customerConfiguration" [configuration]="customerConfiguration"></ct-model>

    <ng-container *ngIf="enableLocationForm">

      <mat-divider></mat-divider>

      <h3> Indirizzi </h3>

      <ct-model *ngIf="locationsModelConfiguration" [configuration]="locationsModelConfiguration"></ct-model>

    </ng-container>`
})
export class CustomerEditComponent {

  customerConfiguration: CtModelConfiguration<CustomerEditComponent> | null = null;
  locationsModelConfiguration: CtModelConfiguration<CustomerEditComponent> | null = null;

  constructor(private route: ActivatedRoute, private onFlyCreateHelper: CtModelOnFlyCreateService) {

    this.setupCustomerConfiguration();

    this.setupLocationConfiguration();

  }

  get enableLocationForm(): boolean {

    if (!this.customerConfiguration) return false;

    return !!this.customerConfiguration.RouteData?.id;

  }

  private setupCustomerConfiguration() {

    const routeData: CtModelRouteData = CUSTOMER_ROUTEDATA()
      .setModelType(CtModelType.FORM);

    const pId: string | null = this.route.snapshot.paramMap.get("id");

    if (pId) routeData.setId(+pId);

    this.customerConfiguration = CtModelConfiguration
      .create<CustomerEditComponent>()
      .setRouteData(routeData);

  }

  private setupLocationConfiguration() {

    const routeData: CtModelRouteData = LocationRouteData()
      .setModelType(CtModelType.DATATABLE);

    this.locationsModelConfiguration = CtModelConfiguration
      .create<CustomerEditComponent>()
      .setRouteData(routeData)
      .setFilterable(false)
      .setFilterValues([
        CTModelDatatableFilter
          .create()
          .setField('Customer.Oid')
          .setValue(this.customerConfiguration?.RouteData?.id)
          .setOperatorType(CtBinaryOperator.Equal)
      ])
      .setOnCreate(() => this.openLocationForm())
      .setOnEdit((location: BeaverLocation) => this.openLocationForm(location.Oid));

  }

  private openLocationForm(Oid: number | null = null) {

    const routeData: CtModelRouteData = CtModelRouteData
      .create()
      .setController(LocationRouteData().controller)
      .setModelType(CtModelType.FORM);

    const configuration: CtModelConfiguration<any> | null = CtModelConfiguration.create()

    if (Oid) {

      routeData
        .setId(Oid);

    }

    if (this.customerConfiguration?.DataSource) {

      configuration
        .setFilterValues([
          CTModelDatatableFilter
            .create()
            .setField('Customer')
            .setValue(this.customerConfiguration?.DataSource)
            .setOperatorType(CtBinaryOperator.Equal)
        ]);

    }

    configuration
      .setRouteData(routeData)
      .setFieldsCustomTemplates([{prop: 'Customer', disabled: true}])

    this.onFlyCreateHelper
      .openOnFlyCreate(configuration)
      .afterClosed()
      .subscribe((reload: any) => {

        if (reload) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

      });

  }

}

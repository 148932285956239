import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HandlingGenericInfoComponent} from "./handling-generic-info.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CtControlModule} from "@ctsolution/ct-framework";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";

@NgModule({
  declarations: [
    HandlingGenericInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CtControlModule,
    FlexLayoutModule,
    FlexModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatListModule
  ],
  exports: [
    HandlingGenericInfoComponent
  ]
})
export class HandlingGenericInfoModule {
}

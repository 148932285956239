import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HandlingListComponent} from "./handling-list.component";
import {CtModelModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    HandlingListComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ]
})
export class HandlingListModule {
}

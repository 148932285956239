import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MatDividerModule} from "@angular/material/divider";
import {RouterModule} from "@angular/router";
import {HandlingListComponent} from "./handling-list/handling-list.component";
import {HandlingCreateComponent} from "./handling-create/handling-create.component";
import {HandlingListModule} from "./handling-list/handling-list.module";
import {ItemSelectionModule} from "./handling-create/_components/item-selection/item-selection.module";
import {
  HandlingGenericInfoModule
} from "./handling-create/_components/handling-generic-info/handling-generic-info.module";
import {SelectedItemsModule} from "./handling-create/_components/selected-items/selected-items.module";
import {HandlingService} from "./handling.service";
import {UnitsOfMeasurementController} from "../../_core/controllers/unitsofmeasurements.controller";
import {ReportController} from "../../_core/controllers/report.controller";

@NgModule({
  declarations: [
    HandlingCreateComponent
  ],
  imports: [
    CommonModule,
    HandlingGenericInfoModule,
    MatDividerModule,
    ItemSelectionModule,
    HandlingListModule,
    SelectedItemsModule,
    RouterModule.forChild([
      {
        path: ":origin",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "create",
            component: HandlingCreateComponent
          },
          {
            path: "edit/:id",
            component: HandlingCreateComponent
          },
          {
            path: "list",
            component: HandlingListComponent
          }
        ]
      }
    ])
  ],
  providers: [
    ReportController,
    HandlingService,
    UnitsOfMeasurementController
  ]
})
export class HandlingModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TemporaryAreaListComponent} from "./temporary-area-list.component";
import {MatLegacyCardModule} from "@angular/material/legacy-card";
import {MatLegacyProgressBarModule} from "@angular/material/legacy-progress-bar";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {FlexModule} from "@angular/flex-layout";
import {TemporaryAreaListBoxComponent} from './temporary-area-list-box/temporary-area-list-box.component';
import {CtButtonModule, CtSkeletonModule} from "@ctsolution/ct-framework";
import {MatLegacySlideToggleModule} from "@angular/material/legacy-slide-toggle";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import { TemporaryAreaChartDetailComponent } from './temporary-area-list-box/temporary-area-chart-detail/temporary-area-chart-detail.component';
import {MatLegacyListModule} from "@angular/material/legacy-list";

@NgModule({
  declarations: [
    TemporaryAreaListComponent,
    TemporaryAreaListBoxComponent,
    TemporaryAreaChartDetailComponent
  ],
  imports: [
    CommonModule,
    MatLegacyCardModule,
    MatLegacyProgressBarModule,
    MatIconModule,
    MatLegacyButtonModule,
    FlexModule,
    CtSkeletonModule,
    CtButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacySlideToggleModule,
    NgxChartsModule,
    MatLegacyListModule
  ],
  exports: [TemporaryAreaListComponent]
})
export class TemporaryAreaListModule {
}

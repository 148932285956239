import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
  CtBinaryOperator,
  CtButtonConfiguration, CtModelConfiguration,
  CTModelDatatableFilter, DeleteConfirmService,
  MAT_RAISED_ACCENT
} from "@ctsolution/ct-framework";
import {UserWorkingHoursPicturesConfiguration} from "./user-working-hours-pictures.configuration";
import {UserWorkingHoursFileController} from "../../../../../_core/controllers/user-working-hours-file.controller";
import {BeaverFile} from "../../../../../_core/classes/beaver-file";
import {MAT_LEGACY_DIALOG_DATA} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-user-working-hours-pictures',
  templateUrl: './user-working-hours-pictures.component.html',
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    mat-dialog-content {
      max-height: unset !important;
      flex: 1 0 0;
    }

    .user-working-hours-pictures-gallery {

      min-height: 450px;

    }

    .user-working-hours-pictures-gallery img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .mat-card-header-text {

      width: 100%;
    }

    .gallery-item {

      position: relative;
      overflow: hidden;
      margin: 5px;
      min-height: 150px;
      transition: all 0.3s ease-in-out;

      &.selected {

        min-height: 400px !important;

      }

      button {

        z-index: 2;
        position: absolute;
        right: 10px;

        &.preview {

          top: 10px;

        }

        &.delete {

          bottom: 10px;

        }

      }

      img {

        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.3s ease-in-out;
        z-index: 1;

      }

    }

    @media screen and (max-width: 500px) {

      .gallery-item {

        max-height: 100px !important;

      }

    }

    .gallery-item:hover img {
      transform: scale(1.1);
    }

    .gallery-item.selected img {
      transform: scale(1.5);
    }

    ::ng-deep .activity-picture-skeleton {

      height: 150px !important;
      width: 150px !important;
      display: inline-block !important;
      margin: 5px !important;
      border-radius: 10px;

    }

  `],
  providers: [UserWorkingHoursFileController]
})
export class UserWorkingHoursPicturesComponent implements OnInit {

  @ViewChild('fileUploader') fileUploader: any | null = null;

  pictures: Array<BeaverFile> = []
  loading = true;
  selectedIndex: number | null = null;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: UserWorkingHoursPicturesConfiguration,
    private userWorkingHoursFileController: UserWorkingHoursFileController,
    private deleteConfirm: DeleteConfirmService
  ) {
  }

  ngOnInit() {

    this.getList();

  }

  onUpload(event: any) {

    const files = (event.target as HTMLInputElement).files ?? [];

    if (!files.length) return;

    const promises = [];

    for (let i = 0; i < files.length; i++) {

      const file = files[i];

      const parameter: BeaverFile = BeaverFile
        .create()
        .setFile(file)
        .setUserWorkingHours(this.data.userWorkingHours);

      const promise = this.userWorkingHoursFileController
        .create(parameter)
        .toPromise();

      promises.push(promise);

    }

    Promise.all(promises)
      .then(() => this.getList())
      .catch((error) => console.error(error));

  }

  getList() {

    this.loading = true;

    const filter: CTModelDatatableFilter = CTModelDatatableFilter
      .create()
      .setField("UserWorkingHours.Oid")
      .setValue(this.data.userWorkingHours?.Oid)
      .setOperatorType(CtBinaryOperator.Equal);

    this.userWorkingHoursFileController
      .get([filter])
      .then((result: CtModelConfiguration<Array<BeaverFile>>) => {
        this.pictures = (<Array<BeaverFile>>result.DataSource ?? []).map(elm => BeaverFile.create(elm));
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
        this.loading = false;
      });

  }

  selectPicture(selectedIndex: number | null) {

    this.selectedIndex = this.selectedIndex === selectedIndex ? null : selectedIndex;

  }

  deletePicture(file: BeaverFile) {

    if (!file.Oid) return;

    this.deleteConfirm
      .open()
      .afterClosed()
      .subscribe(confirm => {

        if (confirm) {

          this.userWorkingHoursFileController
            .delete(file)
            ?.subscribe(() => this.getList());

        }

      })

  }

}

import { CtFileUploaderConfiguration } from "@ctsolution/ct-framework";
import { BEAVER_IMPORT_CONFIGURATION } from "./beaver-import.constant";
import { Observable } from "rxjs";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi";

export class BeaverImportConfiguration {

  get closeActionEnabled(): boolean | null {
    return this._closeActionEnabled;
  }

  private set closeActionEnabled(value: boolean | null) {
    this._closeActionEnabled = value;
  }

  get importSubscriptionCallBack(): (<T>(response: CtWebapiGenericResponse<T>) => void) | null {
    return this._importSubscriptionCallBack;
  }

  private set importSubscriptionCallBack(value: (<T>(response: CtWebapiGenericResponse<T>) => void) | null) {
    this._importSubscriptionCallBack = value;
  }


  get template(): BeaverFileTemplate | null {
    return this._template;
  }

  private set template(value: BeaverFileTemplate | null) {
    this._template = value;
  }

  get CTFileUploaderConfiguration(): CtFileUploaderConfiguration | null {
    return this._CTFileUploaderConfiguration;
  }

  private set CTFileUploaderConfiguration(value: CtFileUploaderConfiguration | null) {
    this._CTFileUploaderConfiguration = value;
  }

  get description(): string | null {
    return this._description;
  }

  private set description(value: string | null) {
    this._description = value;
  }

  get subtitle(): string | null {
    return this._subtitle;
  }

  private set subtitle(value: string | null) {
    this._subtitle = value;
  }

  get title(): string | null {
    return this._title;
  }

  private set title(value: string | null) {
    this._title = value;
  }

  get importSubscription(): ((file: File) => Observable<any>) | null {
    return this._importSubscription;
  }

  private set importSubscription(value: ((file: File) => Observable<any>) | null) {
    this._importSubscription = value;
  }

  private _template: BeaverFileTemplate | null = null;
  private _CTFileUploaderConfiguration: CtFileUploaderConfiguration | null = BEAVER_IMPORT_CONFIGURATION();
  private _title: string | null = null;
  private _subtitle: string | null = null;
  private _description: string | null = null;
  private _importSubscription: ((file: File) => Observable<any>) | null = null;
  private _importSubscriptionCallBack: (<T>(response: CtWebapiGenericResponse<T>) => void) | null = null;
  private _closeActionEnabled: boolean | null = null;

  constructor() {
  }

  public static create = (): BeaverImportConfiguration => new BeaverImportConfiguration();

  setCTFileUploaderConfiguration(value: CtFileUploaderConfiguration | null): BeaverImportConfiguration {

    this.CTFileUploaderConfiguration = value;
    return this;

  }

  setTitle(value: string | null): BeaverImportConfiguration {

    this.title = value;
    return this;

  };

  setSubtitle(value: string | null): BeaverImportConfiguration {

    this.subtitle = value;
    return this;

  };

  setDescription(value: string | null): BeaverImportConfiguration {

    this.description = value;
    return this;

  };

  setImportSubscription(value: ((file: File) => Observable<any>) | null): BeaverImportConfiguration {

    this.importSubscription = value;
    return this;

  }

  setImportSubscriptionCallBack(value: (<T>(response: CtWebapiGenericResponse<T>) => void) | null): BeaverImportConfiguration {

    this.importSubscriptionCallBack = value;
    return this;

  }

  setTemplate(value: BeaverFileTemplate): BeaverImportConfiguration {

    this.template = value;
    return this;

  }

  enableCloseAction(value: boolean): BeaverImportConfiguration {

    this.closeActionEnabled = value;
    return this;

  }

}


export interface BeaverFileTemplate {

  path: string | null;
  fileName: string | null;

}

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {SelectedItemRowConfiguration} from "./selected-item-row.configuration";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CtSelectControlValue} from "@ctsolution/ct-framework";
import {UnitsOfMeasurementController} from "../../../../../../_core/controllers/unitsofmeasurements.controller";
import {HandlingStateEnum} from "../../../../../../_core/enum/handling-state.enum";

@Component({
  selector: "app-selected-item-row",
  templateUrl: "./selected-item-row.component.html",
  styleUrls: ["./selected-item-row.component.scss"]
})
export class SelectedItemRowComponent implements OnInit {

  @Input() configuration: SelectedItemRowConfiguration | null = null;
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onTracking: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  constructor(private formBuilder: FormBuilder, private UMController: UnitsOfMeasurementController) {

    this.form = this.formBuilder.group({});

  }

  ngOnInit() {

    this.configuration
      ?.qtyControl
      .setFormParent(this.form);

    this.configuration
      ?.startQtyControl
      .setFormParent(this.form);

    this.configuration
      ?.UMControl
      .setFormParent(this.form);

  }

  get enableStartQtyControl(): boolean {

    return this.configuration?.handlingState === HandlingStateEnum.CheckIn;

  }

  get handlingIsDraftStatus(): boolean {

    return this.configuration?.handlingState === HandlingStateEnum.Draft;

  }

  ngAfterViewInit() {

    this.UMController
      .get()
      .then((list: CtSelectControlValue[]) => {

        this.configuration
          ?.UMControl
          .setValueOptions(list);

      });

  }

  delete() {

    this.onDelete.emit();

  }

}

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SelectedItemRowComponent} from "./selected-item-row.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {CtControlModule} from "@ctsolution/ct-framework";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";

@NgModule({
  declarations: [
    SelectedItemRowComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    FlexLayoutModule,
    CtControlModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [SelectedItemRowComponent]
})
export class SelectedItemRowModule {
}

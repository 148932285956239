import {Injectable} from '@angular/core';
import {UserWorkingHours} from "../../../../../_core/classes/user-working-hours";
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {UserWorkingHoursPicturesComponent} from "./user-working-hours-pictures.component";
import {UserWorkingHoursPicturesConfiguration} from "./user-working-hours-pictures.configuration";

@Injectable()
export class UserWorkingHoursPicturesService {

  constructor(private dialog: MatLegacyDialog) {
  }

  open = (userWorkingHours: UserWorkingHours) => {

    const data = UserWorkingHoursPicturesConfiguration
      .create()
      .setUserWorkingHours(userWorkingHours);

    this.dialog.open(UserWorkingHoursPicturesComponent, {
      data,
      // height: "calc(100% - 30px)",
      // width: "calc(100% - 30px)",
      maxWidth: "99%",
      maxHeight: "99%",
      panelClass: 'user-working-hours-pictures-container'
    })

  }

}

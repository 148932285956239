import {Injectable} from "@angular/core";
import {DataRequest} from "@ctsolution/ct-webapi";
import {ControllerHelper} from "./controller.helper";
import {BeaverLocation} from "../classes/beaver-location";
import {CtModelConfiguration} from "@ctsolution/ct-framework";

const CONTROLLER: string[] = ["location"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class LocationController {

  constructor(private _helper: ControllerHelper) {
  }

  get = (): Promise<CtModelConfiguration<Array<BeaverLocation>>> => this._helper.list(CONTROLLER_REQUEST());

  getEnabledLocations(): Promise<Array<BeaverLocation>> {

    return new Promise<Array<BeaverLocation>>((resolve) => {

      this.get()
        .then((result: CtModelConfiguration<Array<BeaverLocation>>) => {

          // filtro le location abilitate
          const enabledLocations: Array<BeaverLocation> = (<BeaverLocation[]>(result.DataSource ?? []))
            .filter(elm => elm.IsEnabled);

          // prependo il nome della commessa al nome del deposito (se presente) AB#6768

          enabledLocations
            .forEach(location => {

              if (location.WorkOrder?.Name) {

                location.Name = location.Name?.replace(/^/, `${location.WorkOrder.Name} - `) ?? "";

              }

            });

          enabledLocations
            .sort((a: BeaverLocation, b: BeaverLocation) => {

              const nameA = (a.Name ?? '').toUpperCase(); // Converte il nome in maiuscolo per ordinare in modo case-insensitive
              const nameB = (b.Name ?? '').toUpperCase();

              return nameA.localeCompare(nameB); // Ordina in ordine alfabetico crescente

            });

          resolve(enabledLocations);

        });

    });

  }

}

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CatalogListComponent} from "./catalog-list.component";
import {CtModelModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    CatalogListComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ]
})
export class CatalogListModule {
}

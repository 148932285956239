import {Component, Inject} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {BeaverLocation} from "../../../../../../_core/classes/beaver-location";

@Component({
  selector: 'app-temporary-area-chart-detail',
  templateUrl: './temporary-area-chart-detail.component.html',
  styleUrls: ['./temporary-area-chart-detail.component.scss']
})
export class TemporaryAreaChartDetailComponent {

  GELO = [
    {
      name: 'Pizza surgelata',
      qta: Math.floor(Math.random() * 4) + 1
    },
    {
      name: 'Nuggets di pollo',
      qta: Math.floor(Math.random() * 4) + 1
    }
  ];

  FRESCO = [
    {
      name: 'Mozzarella',
      qta: Math.floor(Math.random() * 4) + 1
    },
    {
      name: 'Prosciutto crudo',
      qta: Math.floor(Math.random() * 4) + 1
    }
  ]

  SECCO = [
    {
      name: 'Pasta',
      qta: Math.floor(Math.random() * 4) + 1
    },
    {
      name: 'Riso',
      qta: Math.floor(Math.random() * 4) + 1
    }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BeaverLocation,
    private dialogRef: MatDialogRef<TemporaryAreaChartDetailComponent>) {
  }

  close() {

    this.dialogRef.close();

  }

}

import {AfterViewInit, ChangeDetectorRef, Component, ViewChild} from "@angular/core";
import {CTGeneralService, CtModelConfiguration, CtModelService, SnackbarService} from "@ctsolution/ct-framework";
import {ActivatedRoute, Router} from "@angular/router";
import {HandlingGenericInfoComponent} from "./_components/handling-generic-info/handling-generic-info.component";
import {SelectedItemsConfiguration} from "./_components/selected-items/selected-items.configuration";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {HandlingService} from "../handling.service";
import {distinctUntilChanged} from "rxjs";
import {ItemSelectionConfiguration} from "./_components/item-selection/item-selection/item-selection.configuration";
import {ItemSelectionService} from "./_components/item-selection/item-selection.service";
import {HandlingController} from "../../../_core/controllers/handling.controller";
import {Handling} from "../../../_core/classes/handling";
import {HandlingOriginEnum} from "../../../_core/enum/handling-origin.enum";
import {HandlingStateEnum} from "../../../_core/enum/handling-state.enum";
import {HandlingRouteData} from "../../../_core/route-data/handling.route-data";

@Component({
  selector: "app-handling-create",
  templateUrl: "./handling-create.component.html",
  styleUrls: ["./handling-create.component.scss"],
  providers: [HandlingController]
})
export class HandlingCreateComponent implements AfterViewInit {

  @ViewChild("genericInfo") genericInfo: HandlingGenericInfoComponent | undefined;

  selectedItemsConfiguration: SelectedItemsConfiguration = SelectedItemsConfiguration.create();
  itemSelectionConfiguration: ItemSelectionConfiguration = ItemSelectionConfiguration
    .create()
    .setSelectedItemsConfiguration(this.selectedItemsConfiguration);

  configuration: CtModelConfiguration<Handling> = CtModelConfiguration
    .create<Handling>()
    .setRouteData(HandlingRouteData());

  constructor(
    private controller: HandlingController,
    private router: Router,
    private route: ActivatedRoute,
    private _model: CtModelService<Handling>,
    private _snackbar: SnackbarService,
    public general: CTGeneralService,
    private cdr: ChangeDetectorRef,
    private handlingService: HandlingService,
    public itemSelectionService: ItemSelectionService) {
  }

  ngAfterViewInit() {

    const paramId: string | null = this.route.snapshot.paramMap.get("id");

    this.setupOrigin(!!paramId);

    if (paramId) {

      this.configuration
        ?.RouteData
        ?.setId(+paramId);

      this._model
        .getInfos(this.configuration)
        ?.subscribe((response: CtWebapiGenericResponse<CtModelConfiguration<Handling>>) => {

          const value = response.Result.DataSource as Handling;

          if (!value) return;

          setTimeout(() => this.setupHandling(value));

        });

    }

    this.genericInfo
      ?.form
      .valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => this.updateConfigurations());

    this.cdr.detectChanges();

  }

  updateConfigurations() {

    const origin: HandlingOriginEnum | null = this.genericInfo?.form.value.HandlingOrigin;

    const locationFromOid: number | null = this.genericInfo?.form.value.LocationFromOid;
    const supplierFromOid: number | null = this.genericInfo?.form.value.SupplierFromSupplierOid;

    this.itemSelectionConfiguration
      .setOrigin(origin)
      .setFrom(origin === HandlingOriginEnum.Supplier ? supplierFromOid : locationFromOid);

    this.selectedItemsConfiguration
      .setHandlingState(this.genericInfo?.form.value.HandlingState);

  }

  setupOrigin(editMode = false) {

    const originPathValue: string | null = this.route.snapshot.paramMap.get("origin");
    const origin: HandlingOriginEnum = this.handlingService.getHandlingTypeByRouteValue(originPathValue);

    this.route
      .data
      .subscribe(data => {

        data['title'] = editMode ? `Modifica` : `Creazione`;

        switch (origin) {

          case HandlingOriginEnum.Supplier:
            data['title'] += ` Ordine`;
            break;
          case HandlingOriginEnum.Location:
          default:
            data['title'] += ` Movimentazione`;
            break;
        }

      });

  }

  setupHandling(value: Handling) {

    this.genericInfo
      ?.setupValue(value);

    if (value.ItemHandlings) {

      this.itemSelectionService
        .setupItemHandlings(value.ItemHandlings, this.selectedItemsConfiguration);

    }

  }

  submit(stateChange?: HandlingStateEnum) {

    this.genericInfo?.form.markAllAsTouched();

    if (this.genericInfo?.form.invalid) {

      this.genericInfo?.openAccordion();

    }

    if (this.genericInfo?.form.valid && this.selectedItemsConfiguration.selected.length) {

      const genericInfoValue = this.genericInfo.form.getRawValue();

      const handling: Handling = Handling.create(genericInfoValue);

      handling
        .setLocationTo(genericInfoValue.LocationToOid)
        .setItemHandlings(this.selectedItemsConfiguration.getItemHandlings());

      if (handling.HandlingOrigin === HandlingOriginEnum.Location) {

        handling.setLocationFrom(genericInfoValue.LocationFromOid);

      } else {

        handling.setSupplierFrom(genericInfoValue.SupplierFromSupplierOid);

      }

      delete (<any>handling).LocationToOid;
      delete (<any>handling).LocationFromOid;
      delete (<any>handling).SupplierFromSupplierOid;

      if (stateChange) {

        handling
          .setHandlingState(stateChange);

      }

      this._model
        .putInfos(this.configuration, handling)
        ?.subscribe((response: CtWebapiGenericResponse<number>) => {

          this._snackbar.open("CT_MESSAGES.success_operation");

          if (response.Result && !isNaN(response?.Result)) {

            this.router.navigate(["..", "edit", response.Result], {relativeTo: this.route});

          } else {

            this.general
              .reloadCurrentRoute();

          }

        });

    }

  }

}

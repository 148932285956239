import {
  CtControlConfiguration,
  CtControlTypes,
  CtControlValidator,
  CtRangeControlOptions,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {HandlingStateEnum} from "../../../../../../_core/enum/handling-state.enum";
import {UnitsOfMeasurements} from "../../../../../../_core/classes/units-of-measurements";
import {BeaverItemStock} from "projects/beaver-store/src/app/_core/classes/beaver-item-stock";

export const QtaControl = (name: string): CtControlConfiguration => CtControlConfiguration.create()
  .setName(name)
  .setTheme(CtThemeTypes.MATERIAL)
  .setType(CtControlTypes.COUNTER)
  .setValue(1)
  .setOptions(
    CtRangeControlOptions
      .create()
      .setMin(1))
  .setValidators([CtControlValidator.create({name: "required"} as CtControlValidator)])
  .setLabel("Quantità");

const UMControl = (): CtControlConfiguration => CtControlConfiguration.create()
  .setTheme(CtThemeTypes.MATERIAL)
  .setType(CtControlTypes.ENUMERABLE)
  .setValue(null)
  .setValidators([CtControlValidator.create({name: "required"} as CtControlValidator)])
  .setLabel("Unità di misura")
  .setName("UM");

export class SelectedItemRowConfiguration {

  get itemCategoryTrackEnabled(): boolean | null {
    return this._itemCategoryTrackEnabled;
  }

  private set itemCategoryTrackEnabled(value: boolean | null) {
    this._itemCategoryTrackEnabled = value;
  }

  get BeaverItemStock(): BeaverItemStock | null {
    return this._BeaverItemStock;
  }

  private set BeaverItemStock(value: BeaverItemStock | null) {
    this._BeaverItemStock = value;
  }

  get handlingState(): HandlingStateEnum | null {
    return this._handlingState;
  }

  private set handlingState(value: HandlingStateEnum | null) {
    this._handlingState = value;
  }

  private _BeaverItemStock: BeaverItemStock | null = null;
  private _handlingState: HandlingStateEnum | null = null;
  private _itemCategoryTrackEnabled: boolean | null = null;
  barcodes: string[] = [];

  qtyControl: CtControlConfiguration = QtaControl("qty")
    .setLabel("Quantità");

  startQtyControl: CtControlConfiguration = QtaControl("startQty")
    .setLabel("Quantità Iniziale");

  UMControl: CtControlConfiguration = UMControl();

  private constructor() {
  }

  public static create = (): SelectedItemRowConfiguration => new SelectedItemRowConfiguration();

  setBeaverItemStock(value: BeaverItemStock | null): SelectedItemRowConfiguration {

    this.BeaverItemStock = value;

    if (value?.Stock) {

      this.qtyControl
        .setValue(value.Stock);

    }

    if (value?.UMOid) {

      this.UMControl
        .setValue(value.UMOid);

    }

    return this;

  }

  setQtyValue(value: number | null = 1): SelectedItemRowConfiguration {

    this.qtyControl
      .setValue(value);

    return this;

  }

  setStartQtyValue(value: number | null = 1): SelectedItemRowConfiguration {

    this.startQtyControl
      .setValue(value)
      .setDisabled(true);

    return this;

  }

  setUMValue(value: UnitsOfMeasurements | null): SelectedItemRowConfiguration {

    this.UMControl
      .setValue(value?.Oid);

    if (value && this.BeaverItemStock && !this.BeaverItemStock.UMOid) {

      this.BeaverItemStock.UMOid = value.Oid
      this.BeaverItemStock.UMSymbol = value.Symbol;

    }

    return this;

  }

  setItemCategoryTrackEnabled(value: boolean | null): SelectedItemRowConfiguration {

    this.itemCategoryTrackEnabled = value;
    return this;

  }

  setHandlingState(value: HandlingStateEnum | null): SelectedItemRowConfiguration {

    this.handlingState = value;
    return this;

  }

}

<div class="module-create-form">

  <div>

    <p> Sulla base delle attuali giacenze è possibile generare
      <strong>{{ model?.PossibileModuleQty}}</strong> moduli. </p>

    <p>
      Attenzione: nel caso in cui manchi l'assortimento per uno o più moduli, il sistema consentirà comunque la
      generazione automatica di ordini ai fornitori. <br/> Dopo la conclusione degli ordini, sarà necessario ripetere la
      procedura di generazione del modulo. <br/> Grazie per la comprensione.
    </p>

  </div>

  <form [formGroup]="form">

    <div>

      <p> Seleziona la quantità di moduli che vuoi generare: </p>

      <div
        class="p-10">

        <ct-control [configuration]="qtyControl"></ct-control>

      </div>

    </div>

    <div>

      <p> Seleziona la destinazione: </p>

      <div
        class="p-10">

        <ct-control [configuration]="locationToControl"></ct-control>

      </div>

    </div>

  </form>


</div>

import {AfterViewInit, Component, OnInit} from '@angular/core';
import {
  CtControlConfiguration, CtControlValidator, CTDatatablePaginationParameter,
  CTFieldInfos,
  CtFormContainerHelper, CtModelConfiguration, CtModelDatatableOperators,
  CtSelectControlOptions, CtSelectControlValue, CtSortOrderDescriptorParameter,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {environment} from "../../../../../../environments/environment";
import {JwtService} from "../../../../../_core/lib/jwt.service";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";

const UserWorkingHoursUserInfoControl: CTFieldInfos = CTFieldInfos.create({
  IsDTO: true,
  Field: "UserInfo",
  Type: "UserInfoDTO",
  IsNullable: false,
  ControlType: "Lookup",
  LookupInfos: {
    RemoteEndpoint: `${environment.dev.REST_API_SERVER}/User/Lookup`,
    CodeField: "Code",
    DescriptionField: "Description",
    IncrementalSearch: false,
  },
} as CTFieldInfos);

@Component({
  selector: 'app-user-working-hours-user-info-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursUserInfoControlComponent implements AfterViewInit {

  control: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHoursUserInfoControl))
    .setTheme(CtThemeTypes.MATERIAL)
    .setValidators([CtControlValidator.create({name: 'required'} as CtControlValidator)])

  constructor(private _jwt: JwtService) {
  }

  ngAfterViewInit() {

    this.setup()

  }

  private async setup() {

    const oid = await this._jwt.getUserInfoOid();

    this.control
      .setOptions(
        CtSelectControlOptions
          .create()
          .setLookupFilter(() => {

            const filter = CtModelDatatableOperators
              .create()
              .setPagination(new CTDatatablePaginationParameter())

            if (filter.Pagination) filter.setPagination(new CTDatatablePaginationParameter())

            filter
              .Pagination
              ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(new CtSortOrderDescriptorParameter().setField('Surname')))

            return filter;

          })
          .setLookupResponseMapper((response: CtWebapiGenericResponse<CtModelConfiguration<any>>) => {

            if (!this.control.control?.value) {

              const selectedValue: any | undefined = (<Array<any>>response.Result.DataSource)
                .find(user => user.Code === oid);

              this.control
                ?.setValue(+selectedValue?.Code);

            }

            return (<Array<any>>response.Result.DataSource ?? [])
              .map((elm: any) => {

                return CtSelectControlValue
                  .create()
                  .setLabel(elm.Description)
                  .setValue(elm.Code);

              });

          }))
  }

}

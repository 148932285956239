<mat-card>

  <mat-card-content>

    <mat-card-title-group>

      <mat-card-title>Inserisci articolo</mat-card-title>

    </mat-card-title-group>

    <form
      (keydown.enter)="onSearch()"
      [formGroup]="form">

      <ct-control
        (onFocus)="closeInfoAccordion()"
        [configuration]="itemSearchControl"></ct-control>

    </form>

    <mat-card-actions
      fxLayout="row wrap"
      fxLayoutAlign="space-between center">

      <ct-button [configuration]="searchButton"></ct-button>

      <ct-button [configuration]="importButton"></ct-button>

    </mat-card-actions>

  </mat-card-content>

</mat-card>

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SelectedItemsComponent} from "./selected-items.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {CtButtonModule} from "@ctsolution/ct-framework";
import {SelectedItemRowModule} from "./selected-item-row/selected-item-row.module";
import {MatDividerModule} from "@angular/material/divider";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {MatIconModule} from "@angular/material/icon";
import {BarcodeTrackingComponent} from './barcode-tracking/barcode-tracking.component';
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {MatLegacyFormFieldModule} from "@angular/material/legacy-form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyInputModule} from "@angular/material/legacy-input";
import {MatLegacyProgressBarModule} from "@angular/material/legacy-progress-bar";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyChipsModule} from "@angular/material/legacy-chips";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";

@NgModule({
  declarations: [
    SelectedItemsComponent,
    BarcodeTrackingComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    CtButtonModule,
    SelectedItemRowModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatLegacyDialogModule,
    MatLegacyFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyInputModule,
    MatLegacyProgressBarModule,
    MatLegacyButtonModule,
    MatLegacyChipsModule,
    FlexModule,
    FlexLayoutModule
  ],
  exports: [
    SelectedItemsComponent
  ]
})
export class SelectedItemsModule {
}

import {Component, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {CtButtonConfiguration, MAT_RAISED_PRIMARY} from "@ctsolution/ct-framework";
import {WarehouseService} from "../warehouse.service";
import {BeaverHeaderConfiguration} from "../../../layouts/beaver-header/beaver-header.configuration";

@Component({
  selector: "app-warehouse-detail",
  templateUrl: "./warehouse-detail.component.html",
  styleUrls: ["./warehouse-detail.component.scss"]
})
export class WarehouseDetailComponent {

  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;

  pId: string | null = null;
  headerConfiguration: BeaverHeaderConfiguration | null = null;

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Nuova movimentazione")
    .setAction(() => this.newHandling())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    private route: ActivatedRoute,
    private whService: WarehouseService,
    private router: Router) {

    this.pId = this.route.snapshot.paramMap.get("id");

    this.route
      .queryParams
      .subscribe(qp => {

        this.headerConfiguration = BeaverHeaderConfiguration.create()
          .setTitle(`Dettaglio` + (qp["Name"] ? `: ${qp["Name"]}` : ""));

      });
  }

  ngAfterViewInit() {

    this.headerConfiguration
      ?.setTemplateRef(this.headerActions);

  }

  reportQR() {

    if (!this.pId) return;

    this.whService
      .openQRCodeFromLocation({locationOid: +this.pId, locationName: this.headerConfiguration?.title!});

  }

  reportPDF() {

    if (!this.pId) return;

    this.whService
      .downloadLocationPDF(+this.pId);

  }

  newHandling() {

    this.router
      .navigate(["/", "handling", "movement", "create"], {queryParams: {startFrom: this.pId}});

  }

}

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {WarehouseListComponent} from "./warehouse-list/warehouse-list.component";
import {WarehouseListModule} from "./warehouse-list/warehouse-list.module";
import {WarehouseEditComponent} from "./warehouse-edit/warehouse-edit.component";
import {WarehouseDetailComponent} from "./warehouse-detail/warehouse-detail.component";
import {WarehouseDetailModule} from "./warehouse-detail/warehouse-detail.module";
import {WarehouseEditModule} from "./warehouse-edit/warehouse-edit.module";
import {WarehouseService} from "./warehouse.service";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {CtModelRouteData} from "@ctsolution/ct-framework";
import {ReportController} from "../../_core/controllers/report.controller";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    WarehouseListModule,
    WarehouseDetailModule,
    MatDialogModule,
    WarehouseEditModule,
    RouterModule.forChild([
      {
        path: ":macrotype",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "create",
            component: WarehouseEditComponent
          },
          {
            path: "edit/:id",
            component: WarehouseEditComponent
          },
          {
            path: "detail/:id",
            component: WarehouseDetailComponent
          },
          {
            path: "list",
            component: WarehouseListComponent
          }
        ]
      }

    ])
  ],
  providers: [
    ReportController,
    WarehouseService
  ]
})
export class WarehouseModule {
}

import {SelectedItemRowConfiguration} from "./selected-item-row/selected-item-row.configuration";
import {HandlingStateEnum} from "../../../../../_core/enum/handling-state.enum";
import {BeaverItemStock} from "../../../../../_core/classes/beaver-item-stock";
import {ItemHandling} from "../../../../../_core/classes/item-handling";


export class SelectedItemsConfiguration {
  get handlingState(): HandlingStateEnum | null {
    return this._handlingState;
  }

  private set handlingState(value: HandlingStateEnum | null) {
    this._handlingState = value;
  }

  get selected(): SelectedItemRowConfiguration[] {
    return this._selected;
  }

  private set selected(value: SelectedItemRowConfiguration[]) {
    this._selected = value;
  }

  private _handlingState: HandlingStateEnum | null = null;
  private _selected: SelectedItemRowConfiguration[] = [];

  private constructor() {
  }

  public static create = (): SelectedItemsConfiguration => new SelectedItemsConfiguration();

  selectItems(value: BeaverItemStock[]): SelectedItemsConfiguration {

    const values: SelectedItemRowConfiguration[] = value
      .map((elm: BeaverItemStock) => SelectedItemRowConfiguration
        .create()
        .setBeaverItemStock(elm));

    return this.setSelected(values);

  }

  setSelected(value: SelectedItemRowConfiguration[]): SelectedItemsConfiguration {

    this.selected = [...(this.selected ?? []), ...value];
    this.enableFieldsByState();
    return this;

  }

  getItemHandlings(): ItemHandling[] {

    return this.selected.map((elm: SelectedItemRowConfiguration) => new ItemHandling()
      .setStartQty(+elm.qtyControl.control?.value)
      .setQty(+elm.qtyControl.control?.value)
      .setItemOid(elm.BeaverItemStock?.ItemOid ?? 0)
      .setUMOid(elm.UMControl.control?.value ?? 0));

  }

  setHandlingState(value: HandlingStateEnum | null): SelectedItemsConfiguration {

    this.handlingState = value;
    this.enableFieldsByState();
    return this;

  }

  private enableFieldsByState() {

    this.selected
      .forEach(selected => selected.setHandlingState(this.handlingState))

  }

}
